.cases {
	&__container {
		margin: -15px -23px -50px -23px;
		padding: 15px 0 50px 0;

		@media (max-width: 1199px) {
			margin: -15px -15px -50px -15px;
		}

		@media (max-width: 767px) {
			margin: -15px -10px -30px -10px;
		}
	}

	&__list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		flex-shrink: 0;
		display: flex;
		width: calc(100%/3);
		padding: 0 23px 0;

		@media (max-width: 1199px) {
			padding: 0 15px 0;
		}

		@media (max-width: 991px) {
			width: 312px;
		}

		@media (max-width: 767px) {
			padding: 0 10px 0;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
		box-shadow: 0 15px 23px rgba(147, 147, 147, 0.31);
		border-radius: 5px;

		@media (max-width: 1199px) {
			box-shadow: 0 10px 15px rgba(147, 147, 147, 0.31);
		}

		@media (max-width: 767px) {
			box-shadow: 0 10px 10px rgba(147, 147, 147, 0.21);
		}

		p {
			margin: 0 0 20px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		margin-bottom: 20px;
		padding-bottom: 66.5%;
		overflow: hidden;
		border-radius: 5px 5px 0 0;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		margin: 0 0 16px;
		padding-left: 20px;
		padding-right: 20px;
	}

	&__btn {
		display: block;
		width: calc(100% - 40px);
		margin: auto auto 0;
	}
}