.commodity-view {

	&__inner {
		max-width: 780px;
		padding: 0 0 60px;

		@media (max-width: 767px) {
			padding-bottom: 40px;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		margin-bottom: 40px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		overflow: hidden;

		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
	}

	&__header {
		width: calc(100% - 120px);
		margin: 0 auto 50px;
		border-bottom: 1px solid $accent-color;

		@media (max-width: 767px) {
			width: calc(100% - 40px);
			margin-bottom: 20px;
		}
	}

	&__title {
		width: 100%;
		margin: 0 0 16px;
		font-size: 32px;
		line-height: 112.5%;
		font-weight: 400;
		color: $text-main-color;
		text-align: center;

		@media (max-width: 767px) {
			margin-bottom: 12px;
			font-size: 28px;
		}
	}

	&__desc {
		padding: 0 130px;
		margin-bottom: 26px;

		@media (max-width: 767px) {
			padding: 0 20px;
		}

		p {
			margin: 0 0 16px;
		}

		*:last-child {
			margin-bottom: 0;
		}
	}

	&__controls {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 130px;

		@media (max-width: 767px) {
			padding: 0 20px;
		}

		@media (max-width: 459px) {
			flex-wrap: wrap;
		}
	}

	&__price {
		display: flex;
		flex-direction: column;
		font-size: 22px;
		line-height: 112.5%;

		@media (max-width: 767px) {
			font-size: 20px;
		}

		span {
			margin-top: 4px;
		}
	}

	&__btn {
		width: 130px;
		margin-top: 4px;

		@media (max-width: 459px) {
			width: 100%;
			margin-top: 30px;
		}
	}
}