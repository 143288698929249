.header {
    position: relative;

    @media (max-width: 991px) {
        position: sticky;
        z-index: 1000;
        top: 0;
        width: 100%;
    }

    &__top {
        position: relative;
        z-index: 1001;
        min-height: 40px;
        font-size: 12px;
        color: $text-white;
        background: $header-top-bg;

        @media (max-width: 991px) {
            display: none;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__social {
        margin-right: 64px;
    }

    &__logo {
        display: block;
        margin-right: auto;
    }
}

.header-contacts {
    display: flex;
    justify-content: flex-end;

    &__list {
        margin: 0 0 0 64px;
        padding: 0;
        list-style: none;
    }

    &__link {
        text-decoration: underline solid transparent;

        &:hover,
        &:focus {
            color: $accent-color;
        }
    }
}

.header-nav {
    position: relative;
    padding: 21px 0;
    background: $header-bg;

    @media (max-width: 991px) {
        display: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__btn {
        margin-left: 26px;
    }

    &__item {
        margin-left: 4px;
    }

    &__link {
        display: block;
        vertical-align: baseline;
        padding: 4px 16px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: $text-main-color;
        text-decoration: underline solid transparent;
        border-radius: 3px 3px 0 0;
        border-bottom: 2px solid transparent;

        &:hover,
        &:focus {
            color: $accent-color;
        }
    }

    &__dropdown {
        position: relative;
    }

    &__dropdown &__link {
        position: relative;
        z-index: 100;

        span {
            display: inline-block;
            vertical-align: baseline;
            transition: all 0.3s ease-in-out;

            &::after {
                position: relative;
                display: inline-block;
                vertical-align: baseline;
                width: 9px;
                height: 9px;
                margin-bottom: 2px;
                margin-left: 8px;
                border-bottom: 1px solid $text-main-color;
                border-right: 1px solid $text-main-color;
                transform: rotate(45deg);
                transition: all 0.3s ease-in-out;
                content: '';
            }

            &:hover,
            &:focus {
                color: $accent-color;

                &::after {
                    border-color: $accent-color;
                }
            }
        }
    }

    &__dropdown:hover &__link {
        border-color: $accent-color;
        box-shadow: 0 -12px 12px -12px rgba($black, 0.11), 12px 0 12px -9px rgba($black, 0.11), -12px 0 12px -9px rgba($black, 0.11);
    }


    &__dropdown:hover span::after {
        top: 5px;
        transform: rotate(45deg) scale(-1);
        border-color: $accent-color;
    }

    &__submenu {
        position: absolute;
        height: 0;
        overflow: hidden;
        z-index: 10;
        top: calc(100% - 1px);
        width: 160px;
        padding-top: 1px;
        background-color: transparent;
        box-shadow: 0 12px 12px -12px rgba($black, 0.11), 12px 0 12px -9px rgba($black, 0.11), -12px 0 12px -9px rgba($black, 0.11);
        transition: all 0.3s ease-in-out;
        visibility: hidden;
        opacity: 0;

        &-list {
            margin: 0;
            padding: 0;
        }

        &-link {
            display: inline-block;
            vertical-align: baseline;
            width: 100%;
            padding: 10px 16px;
            font-size: 16px;
            line-height: 18px;
            color: $text-main-color;
            background: $menu-link-bg;
            font-weight: 500;
            text-decoration: underline solid transparent;
            border-bottom: 1px solid $text-third-color;

            &:hover,
            &:focus {
                background: $menu-link-bg-hover;
            }
        }
    }

    &__dropdown:hover &__submenu {
        height: auto;
        visibility: visible;
        opacity: 1;
    }
}

.social {
    &__list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -4px 0 -8px;
        padding: 0;
        list-style: none;
    }

    &__item {
        width: 32px;
        height: 24px;
        padding: 0 4px;
        margin: 4px;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        // padding: 4px;

        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: $white;
        }
    }

    &--dark &__link {
        padding: 0;

        svg {
            fill: $text-main-color;
        }
    }

    &__link:hover svg,
    &__link:focus svg {
        fill: $accent-color;
    }
}

.header-fixed {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    transform: translateY(-100%);
    padding: 9px 0;
    background-color: $header-top-bg;
    color: $text-white;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    @media (max-width: 991px) {
        position: static;
        transform: translateY(0);
        opacity: 1;
        transition: all 0s;
    }

    &__container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__toggler {
    	display: none;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        margin: 0 0 0 -11px;
        border: none;
        background-color: transparent;

        @media (max-width: 991px) {
        	display: flex;
        }

        @media (max-width: 374px) {
            width: 35px;
            height: 46px;
            margin-left: -6px;
        }

        span {
            width: 24px;
            height: 2px;
            background-color: $text-white;
            transition: all 0.3s ease-in-out;

            &::before,
            &::after {
                position: absolute;
                left: 11px;
                width: 24px;
                height: 2px;
                background-color: $text-white;
                transition: all 0.3s ease-in-out;
                content: '';

                @media (max-width: 374px) {
                    left: 6px;
                }
            }

            &::before {
                top: 16px;
            }

            &::after {
                bottom: 16px;
            }
        }

        &:hover span,
        &.active span {
            background-color: $accent-color;

            &::before,
            &::after {
                background-color: $accent-color;
            }
        }

        &.active span {
            width: 0;
        }

        &.active span::before {
            transform: rotate(45deg) translate(3px, 6.5px);
            width: 31px;
        }

        &.active span::after {
            transform: rotate(-45deg) translate(2px, -5.5px);
            width: 31px;
        }
    }

    &__phone {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        margin: 0 16px 0 0;


        @media (max-width: 991px) {
        	display: flex;
        }

        @media (max-width: 374px) {
            width: 35px;
            height: 35px;
            margin-right: 5px;
        }

        svg {
            width: 24px;
            height: 24px;
            fill: $text-white;
            object-fit: contain;
        }

        &:hover svg,
        &:focus svg {
            fill: $accent-color;
        }
    }

    &__logo {
        width: 116px;
        height: 36px;
    }

    &__btn {
        margin-left: auto;
    }

    &__nav {
    	margin-left: auto;
    	margin-right: auto;
        padding: 0;
        background-color: transparent;

        @media (max-width: 991px) {
        	display: none;
        }
    }

    &__nav .header-nav__link {
        color: $text-white;

        &:hover,
        &:focus {
        	color: $accent-color;
        }

        >span::after {
            border-bottom-color: $text-white;
            border-right-color: $text-white;
        }
    }
}

.header.fixed .header-fixed {
    opacity: 1;
    transform: translateY(0);
}