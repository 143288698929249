.lead-block {
	background: $additional-color;

	p {
		width: 910px;
		max-width: 100%;
		margin: 0 auto;
		font-size: 44px;
		line-height: 49px;
		text-align: center;
		font-weight: 600;

		@media (max-width: 767px) {
			padding: 0 10px;
			font-size: 30px;
			line-height: 112.5%;
		}

		strong {
			font-weight: 600;
			color: $accent-color;
		}
	}
}