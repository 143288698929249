.guarantees {
	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 910px;
		max-width: 100%;
		margin: 0 auto;

		@media (max-width: 767px) {
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-start;
			padding: 0 10px;
		}
	}

	&__desc {
		max-width: 455px;
		padding-right: 15px;

		@media (max-width: 767px) {
			max-width: 100%;
			margin-bottom: 36px;
			padding-right: 0;
		}
	}

	&__title {
		display: inline-block;
		vertical-align: baseline;
		margin: 0 0 32px;
		font-size: 32px;
		line-height: 112.5%;
		font-weight: 400;
		color: $text-main-color;

		img {
			display: inline-block;
			vertical-align: baseline;
			margin-left: 10px;
			margin-top: -10px;

			@media (max-width: 575px) {
				width: 32px;
				height: 28px;
			}
		}

		@media (max-width: 575px) {
			margin-bottom: 24px;
			font-size: 28px;
		}

		strong {
			color: $accent-color;
			font-weight: 400;
		}
	}

	p {
		margin: 0;
		font-size: 22px;
		line-height: 112.5%;
	}

	&__image {
		flex-shrink: 0;
		width: 390px;
		height: auto;

		@media (max-width: 991px) {
			width: 270px;
		}
	}
}