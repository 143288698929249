.why-we {
	
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: -44px -22px 0;
		padding: 0;
		list-style: none;

		@media (max-width: 991px) {
			margin: -30px -10px 0;
		}

		@media (max-width: 575px) {
			margin: -40px -10px 0;
		}
	}

	&__item {
		width: calc(100%/3);
		padding: 0 22px;
		margin-top: 44px;

		@media (max-width: 991px) {
			padding: 0 10px;
			margin-top: 20px;
		}

		@media (max-width: 767px) {
			width: 50%;
		}

		@media (max-width: 575px) {
			width: 100%;
			margin-top: 40px;
		}

		p {
			margin-top: 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		padding: 30px 28px;
		background: $block-bg-main;
		box-shadow: 0px 0px 29px rgba(184, 184, 184, 0.25);
		border-radius: 5px;
	}

	&__icon {
		flex-shrink: 0;
		width: 60px;
		height: 60px;
		margin-bottom: 50px;

		img {
			display: block;
			width: 100%;
			height: 100%;
			objec-fit: contain;
		}
	}

	&__title {
		margin: 0 0 20px;
	}
}
