.contacts-block {

	&__list {
		max-width: 530px;
		margin: 0 auto;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin-bottom: 28px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 575px) {
			justify-content: flex-start;
		}
	}

	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 16px;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			fill: $text-main-color;
		}
	}

	&__text {
		margin: 0;
		font-weight: 300;
		text-align: center;
		font-size: 22px;
		line-height: 25px;
		font-size: 24px;
		line-height: 27px;
		text-decoration: underline solid transparent;

		@media (max-width: 575px) {
			margin-top: 20px;
			font-size: 20px;
			line-height: 22px;
			text-align: left;
		}
	}

	a:hover,
	a:focus {
		color: $accent-color;
	}

	&__icon + &__text {
		font-weight: 400;
		text-align: left;

		@media (max-width: 575px) {
			margin-top: 0;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
		}
	}
}