.subscribe-modal {
	&__title {
		max-width: 320px;
		text-align: center;
	}

	&__form {
		max-width: 320px;
	}

	&__btn {
		display: block;
		width: 100%;
	}
}