.screen-reviews {
	&__list {
		display: flex;
		margin: 0 -10px -20px;
		padding: 0;
		list-style: none;

		@media (max-width: 767px) {
			margin: 0;
		}
	}

	&__item {
		flex-shrink: 0;
		width: 25%;
		padding: 0 10px;
		margin-bottom: 20px;

		@media (max-width: 991px) {
			width: calc(100%/3);
		}

		@media (max-width: 767px) {
			min-width: 216px;
			padding: 0;
		}
	}

	&__inner {
		display: block;
		position: relative;
		width: 100%;
		padding-bottom: 180%;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}