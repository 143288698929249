.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	width: 100vw;
	height: 100vh;
	padding: 15px;
	background-color: rgba($black, 0.4);
	overflow: auto;

	@media (max-width: 767px) {
		padding: 10px;
	}

	&__container {
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 100%;
	}

	&__inner {
		position: relative;
		background: $bg-main-color;
		border-radius: 5px;
		max-width: 100%;
		margin: 0 auto;
		padding: 60px;

		@media (max-width: 767px) {
			padding: 60px 20px;
		}
	}

	&__close {
		position: absolute;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		right: 10px;
		width: 44px;
		height: 44px;
		padding: 0;
		margin: 0;
		border-radius: 50%;
		background-color: transparent;
		border: none;

		span {
			width: 26px;
			height: 26px;
			border-radius: 50%;
			border: 1px solid transparent;
			transition: all 0.3s ease-in-out;
		}

		svg {
			width: 24px;
			height: 24px;
			object-fit: contain;
			fill: $text-third-color;
		}

		&:hover,
		&:focus {
			span {
				background-color: $accent-color;
				border-color: $accent-color;
			}
			
			svg {
				fill: $white;
			}
		}
	}

	h2 {
		text-align: center;
	}
}