.interrupting {
    position: relative;

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $bg-overlay;
        content: "";
    }

    &.color-inversion {
        &::before {
            background: $bg-overlay-inv;
        }
    }

    &__row {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 575px) {
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 10px;
        }

        p {
            margin: 0 0 32px;
            padding-right: 24px;
            font-size: 20px;
            line-height: 22.5px;
            font-weight: 600;

            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 112.5%;
            }

            @media (max-width: 575px) {
                margin-bottom: 24px;
                padding-right: 0;
            }
        }
    }

    &__phone {
        flex-shrink: 0;
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 112.5%;
        font-weight: 400;
        text-decoration: underline solid transparent;

        @media (max-width: 767px) {
            font-size: 32px;
        }

        @media (max-width: 767px) {
            margin-bottom: 40px;
        }

        &:hover,
        &:focus {
            color: $accent-color;
        }
    }

    &__btn {
        margin: 0 0 0 24px;

        @media (max-width: 575px) {
            margin-left: 0;
            width: 100%;
        }
    }
}

.content-social {
    padding: 0 8px;

    @media (max-width: 575px) {
        margin-bottom: 40px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        padding: 0;
        list-style: none;
    }

    &__item {
        padding: 8px 8px 0;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;

        svg {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            fill: $white;
        }

        &:hover,
        &:focus {
            svg {
                fill: $accent-color;
            }
        }
    }
}