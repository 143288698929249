.facts {
	position: relative;
	padding: 125px 0;

	&::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $bg-overlay;
        content: "";
    }

    &.color-inversion {
        &::before {
            background: $bg-overlay-inv;
        }
    }

	@media (max-width: 991px) {
		padding: 80px 0;
	}

	@media (max-width: 767px) {
		padding: 60px 0;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -25px -50px;
		padding: 0;
		list-style: none;

		@media (max-width: 991px) {
			margin: 0 -15px -30px;
		}

		@media (max-width: 767px) {
			flex-direction: column;
			justify-content: flex-start;
			margin: 0 0 -45px;
		}
	}

	&__item {
		width: calc(100%/3);
		padding: 0 25px 50px;

		@media (max-width: 991px) {
			padding: 0 15px 30px;
		}

		@media (max-width: 767px) {
			width: 100%;
			max-width: 335px;
			margin: 0 auto 45px;
			padding: 0;
		}
	}

	&__desc {
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;
	}

	&__number {
		margin-bottom: 12px;
		font-size: 70px;
		line-height: 77px;
		font-weight: 600;

		@media (max-width: 991px) {
			margin-bottom: 8px;
			font-size: 58px;
			line-height: 64px;
		}
	}

	&__title {
		margin-bottom: 28px;
		font-size: 32px;
		line-height: 36px;
		font-weight: 400;
		text-transform: uppercase;

		@media (max-width: 991px) {
			margin-bottom: 16px;
			font-size: 20px;
			line-height: 22.5px;
			font-weight: 300;
		}
	}

	&__text {
		font-size: 22px;
		line-height: 25px;
		font-weight: 300;

		@media (max-width: 991px) {
			font-size: 16px;
			line-height: 18px;
		}
	}
}