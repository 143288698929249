.stages-block {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px -40px;
		padding: 0;
		list-style-type: none; 
		counter-reset: num;

		@media (max-width: 767px) {
			flex-direction: column;
			margin: 0 0 -40px;
		}
	}

	&__item {
		position: relative;
		width: 25%;
		padding: 162px 10px 40px;

		@media (max-width: 991px) {
			padding-top: 125px;
		}

		@media (max-width: 767px) {
			width: 100%;
			max-width: 335px;
			padding: 0 0 0 120px;
			margin: 0 auto 40px;
		}

		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 130px;
			height: 130px;
			padding-top: 7px;
			font-size: 84px;
			line-height: 1;
			color: $accent-color;
			font-weight: 600;
			border: 7px solid $additional-color;
			border-radius: 50%;
			content: counter(num);
			counter-increment: num;

			@media (max-width: 991px) {
				width: 100px;
				height: 100px;
				font-size: 75px;
			}

			@media (max-width: 767px) {
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
			}
		}

		&::after {
			position: absolute;
			top: 65px;
			right: -40px;
			height: 8px;
			width: 80px;
			transform: tanslateY(-50%);
			background-image: $stages-arrow-right;
			background-repeat: no-repeat;
			background-position: right;
			background-size: auto 100%;
			content: "";

			@media (max-width: 991px) {
				top: 50px;
				right: -15px;
				width: 32px;
			}

			@media (max-width: 767px) {
				top: calc(100% + 15px);
				left: 50px;
				transform: translate(-50%, 0) rotate(90deg);
			}
		}

		&:nth-child(4n) {
			&::after {
				display: none;

				@media (max-width: 767px) {
					display: block;
				}
			}
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
	&__inner {
		padding: 0 10px;
		text-align: center;

		@media (max-width: 991px) {
			padding: 0;
		}

		a {
			color: $accent-color;
		}
	}

	&__title {
		margin: 0 0 16px;

		@media (max-width: 991px) {
			font-size: 18px;
			line-height: 112.5%;
		}
	}
}