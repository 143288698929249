.modal-reviews {
	
	&__inner {
		width: 910px;
		max-width: 100%;
		background-color: $bg-second-color;
	}

	&__form {
		max-width: 520px;
		margin: 0 auto;
	}
}