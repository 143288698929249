.reviews-section {
	&__list {
		display: flex;
		align-items: flex-start;
		margin: 0 -20px;
		padding: 0;
		list-style: none;

		@media (max-width: 991px) {
			margin: 0;
		}
	}

	&__item {
		flex-shrink: 0;
		width: 50%;
		padding: 0 20px;

		@media (max-width: 991px) {
			padding: 0;
			min-width: 319px;
			max-width: 513px;
		}
	}

	&__inner {
		padding: 32px 40px;
		background: $bg-second-color;
		border-radius: 10px;

		@media (max-width: 991px) {
			padding: 20px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;
	}

	&__image {
		flex-shrink: 0;
		position: relative;
		width: 100px;
		height: 100px;
		margin-right: 16px;
		border-radius: 50%;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	&__caption {
		margin: 0;
		display: flex;
		flex-direction: column;
	}

	&__name {
		margin-bottom: 12px;
		font-size: 22px;
		line-height: 25px;

		@media (max-width: 991px) {
			font-size: 20px;
			line-height: 112.5%;
		}
	}

	&__date {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: $accent-color;
	}

	&__content {
		p {
			margin: 0 0 16px;
		}
	}
}