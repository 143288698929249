.reviews-form {
	position: relative;

	&::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $bg-overlay;
        content: "";
    }

    &.color-inversion {
        &::before {
            background: $bg-overlay-inv;
        }
    }

	&__form {
		width: 520px;
		max-width: 100%;
		margin: 0 auto;
	}
}