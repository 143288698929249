.callback {
	position: relative;

	&::before {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $bg-overlay;
		content: '';
	}

	&.color-inversion {
		&::before {
			background: $bg-overlay-inv;
		}
	}

	.container {
		position: relative;
		z-index: 2;
	}

	&__form {
		max-width: 640px;
		width: 100%;
		margin: 0 auto;
	}

	&__btn {
		width: 100%;
	}
}

.form-captcha {
	position: relative;
	width: 185px;
	height: 46px;
	margin-bottom: 16px;
	border-radius: 5px;
	overflow: hidden;
}