.services {
	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: -60px 0 0;
		padding: 0;
		list-style: none;

		@media (max-width: 767px) {
			margin-top: -15px;
		}
	}

	&__item {
		width: 100%;
		margin: 60px auto 0;

		@media (max-width: 767px) {
			max-width: 335px;
		}
	}
}

.services-item {
	display: flex;

	@media (max-width: 767px) {
		flex-direction: column;
		justify-content: flex-start;
	}

	&__image {
		position: relative;
		flex-shrink: 0;
		width: 325px;
		padding-bottom: 24.4%;
		margin-right: 20px;
		background-color: $bg-second-color;
		overflow: hidden;

		@media (max-width: 767px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 25px;
			padding-bottom: 75%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__desc {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 435px;
		width: 100%;

		> p {
			margin: 0 0 16px;
		}
	}

	&__title {
		width: 100%;
		margin: 0 0 20px;
		font-size: 32px;
		line-height: 36px;
		font-weight: 400;

		@media (max-width: 991px) {
			margin-bottom: 16px;
			font-size: 28px;
			line-height: 31px
		}
	}

	&__price {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 20px;

		@media (max-width: 991px) {
			margin-bottom: 16px;
		}
	}

	&__price-new {
		margin-right: 16px;
		font-size: 24px;
		line-height: 27px;
	}

	&__price-old {
		margin-right: 16px;
		color: $text-third-color;
		font-size: 24px;
		line-height: 27px;
		text-decoration: line-through;
	}

	&__btn {
		margin-top: auto;
		min-width: 160px;

		@media (max-width: 767px) {
			margin-top: 12px;
			width: 100%;
		}
	}
}