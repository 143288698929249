.modal-view {
	&__inner {
		width: 780px;
		max-width: 100%;
		padding: 50px 48px 48px;

		@media (max-width: 767px) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@media (max-width: 575px) {
			padding-top: 32px;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 575px) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}

	&__title {
		width: 100%;
		margin: 0;
		font-size: 32px;
		line-height: 36px;
		color: $text-main-color;
		font-weight: 400;

		@media (max-width: 575px) {
			font-size: 28px;
			line-height: 112.5%;
			margin-bottom: 4px;
		}
	}

	&__labels {
		position: static;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: auto;
		margin: 2px 0 2px 8px;
		padding: 0;

		@media (max-width: 575px) {
			order: -1;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin: 0 0 28px;
			max-width: calc(100% - 60px);
		}
	}

	&__label {
		margin: 0 0 0 8px;

		@media (max-width: 575px) {
			margin: 0 8px 0 0;
		}

		&--sale {
			padding: 6px 8px 2px;
			font-size: 13px;

		}

		&--discount {
			background-color: #FFEBEA;
		}
	}

	&__article {
		margin: 0 0 8px;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: $text-second-color;

		@media (max-width: 575px) {
			margin-bottom: 12px;
		}
	}

	&__container {
		display: flex;

		@media (max-width: 575px) {
			flex-wrap: wrap;
		}
	}

	&__gallery {
		flex-shrink: 0;
		width: 316px;
		margin-right: 24px;

		@media (max-width: 767px) {
			width: calc(50% - 12px);
		}

		@media (max-width: 575px) {
			position: relative;
			left: -20px;
			width: calc(100% + 40px);
			margin-right: 0;
			margin-bottom: 32px;
		}
	}

	&__info {
		width: calc(100% - 340px);

		@media (max-width: 767px) {
			width: calc(50% - 12px);
		}

		@media (max-width: 575px) {
			width: 100%;
		}
	}

	&__price {
		display: flex;
		flex-direction: row;

		@media (max-width: 575px) {
			margin-bottom: 20px;
		}
	}

	&__price--new {
		margin-right: 16px;
		margin-bottom: 16px;
		font-size: 24px;
		line-height: 112.5%;
		font-weight: 400;
	}

	&__price--old {
		margin-bottom: 16px;
		font-size: 24px;
		line-height: 112.5%;
		color: $text-third-color;
		font-weight: 400;
		text-decoration: line-through;
	}

	&__properties {
		width: 275px;
		max-width: 100%;
		margin: 0 0 16px;
		padding: 0;
		list-style: none;

		@media (max-width: 575px) {
			width: 100%;
			margin-bottom: 40px;
		}
	}

	&__properties-item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 8px;

		span {
			position: relative;
			max-width: 40%;
			flex-shrink: 0;

			&:first-child {
				order: -1;
			}
		}

		&::before {
			position: relative;
			flex-grow: 2;
			top: -5px;
			width: 100%;
			height: 1px;
			background-color: $text-third-color;
			content: "";
		}
	}

	&__btn {
		width: 160px;
		margin-bottom: 16px;

		@media (max-width: 575px) {
			width: 100%;
			margin-bottom: 4px;
		}
	}

	&__bottom {
		margin-top: 32px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: -16px -32px 0 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: flex;
		align-items: center;
		padding-right: 32px;
		margin-top: 16px;
	}

	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 8px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__advantage {
		margin: 0;
		font-size: 20px;
		line-height: 112.5%;
		font-weight: 600;
	}
}

.view-gallery {

	&__top {
		width: 100%;
		margin-bottom: 16px;
	}

	&__thumb {
		width: 100%;

		@media (max-width: 575px) {
			display: none;
		}
	}

	&__top-list,
	&__thumb-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__top-item {
		position: relative;
		flex-shrink: 0;
		width: 100%;
		padding-bottom: 66.5%;

		&.swiper-slide-active {
			z-index: 2;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__thumb-item {
		position: relative;
		flex-shrink: 0;
		width: calc(25% - 12px);
		margin-right: 16px;
		padding-bottom: calc(25% - 12px);
		cursor: pointer;
		opacity: 0.4;
		transition: all 0.3s ease-in-out;

		&.swiper-slide-thumb-active {
			opacity: 1;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__pagination {
		display: none;
		position: absolute;
		z-index: 3;
		bottom: 20px;
		left: 20px;
		padding: 4px 10px 2px;
		background-color: rgba($white, 0.7);
		border-radius: 15px;
		font-size: 16px;
		line-height: 112.5%;
		font-weight: 300;
		letter-spacing: -0.09em;
		color: $text-main-color;

		@media (max-width: 575px) {
			display: block;
		}
	}
}