.comparsion-block {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -24px -48px;
		padding: 0;
		list-style: none;

		@media (max-width: 1199px) {
			margin: 0 -15px -30px;
		}

		@media (max-width: 767px) {
			margin: 0 -10px -20px;
		}
	}

	&__item {
		width: 50%;
		padding: 0 24px 48px;

		@media (max-width: 1199px) {
			padding: 0 15px 30px;
		}
		
		@media (max-width: 767px) {
			padding: 0 10px 20px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__image {
		position: relative;
		padding-bottom: 73.5%;
		width: calc(50% - 2px);

		&::after {
			position: absolute;
            display: inline-block;
            vertical-align: baseline;
            text-align: center;
            bottom: 12px;
            width: 88px;
            padding: 4px 0;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
		}

		&:first-child::after {
			left: 12px;
			 content: 'До';
		}

		&:last-child::after {
			right: 12px;
			content: 'После';
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}