.modal-case {
	
	&__inner {
		width: 780px;
		padding: 0;
	}

	&__header {
		display: flex;
		padding: 60px 65px 32px;
		background-color: $additional-color;

		@media (max-width: 575px) {
			padding: 40px 20px 32px;
		}
	}

	&__icon {
		width: 70px;
		height: 60px;
		margin-right: 20px;

		img {
			display: block;
			max-width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	&__main-title {
		margin: 0;
		font-size: 32px;
		line-height: 36px;
		font-weight: 400;

		@media (max-width: 767px) {
			font-size: 28px;
			line-height: 32px;
		}
	}

	&__title {
		margin: 20px 0;
		font-size: 24px;
		line-height: 27px;
		font-weight: 400;

		@media (max-width: 575px) {
			margin-bottom: 16px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		padding: 40px 65px 60px;

		@media (max-width: 575px) {
			padding: 40px 20px;
		}

		p {
			margin: 0 auto 24px;
			max-width: 520px;
		}
	}

	&__slider {
		margin-bottom: 24px;

		@media (max-width: 767px) {
			width: 100%;
		}

		@media (max-width: 575px) {
			width: calc(100% + 20px);
		}
	}
}

.case-slider {

	&__list {
		display: flex;
		margin: 0;
		padding: 0;
	}

	&__item {
		width: 50%;

		@media (max-width: 575px) {
			width: 316px;
		}
	}

	&__img {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 57.5%;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__nav {

		&--prev {
			left: -45px;
		}

		&--next {
			right: -45px;
		}

		@media (max-width: 991px) {
			display: block;
		}

		@media (max-width: 575px) {
			display: none;
		}
	}
}