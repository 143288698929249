.why-we-block {

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: -40px -20px 0;
		padding: 0;

		@media (max-width: 767px) {
			flex-direction: column;
			margin: -40px -10px 0;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: calc(100%/3);
		padding: 0 20px;
		margin-top: 40px;

		@media (max-width: 767px) {
			width: 100%;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 10px;
		}

		p {
			margin: 0 0 20px;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		padding-bottom: 66%;
		margin-bottom: 20px;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		margin: 0 0 15px;
	}

	&__btn {
		margin-top: auto;
	}
}