.commodities  {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: -40px -23px 0;
		list-style: none;

		@media (max-width: 991px) {
			margin-left: -20px;
			margin-right: -20px;
		}

		@media (max-width: 767px) {
			margin-left: -10px;
			margin-right: -10px;
			margin-top: -20px;
		}

		&.swiper-wrapper {
			flex-wrap: nowrap;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__item {
		flex-shrink: 0;
		display: flex;
		width: calc(100%/3);
		padding: 0 23px;
		margin-top: 40px;

		@media (max-width: 991px) {
			width: 50%;
			padding: 0 20px;
		}

		@media (max-width: 767px) {
			padding: 0 10px;
			margin-top: 20px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}

		&.swiper-slide {
			padding: 0;
			margin-top: 0;

			@media (max-width: 991px) {
				width: 270px;
			}
		}
	}
}

.commodity {
	position: relative;
	flex-grow: 2;
	display: flex;
	flex-direction: column;
	padding-top: 12px;

	&__inner {
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		padding: 54px 32px 32px;
		border: 1px solid $text-third-color;
		background: $block-bg-main;
		border-radius: 5px;
		transition: all 0.3s ease-in-out;
	}

	&:hover &__inner {
		background: $additional-color;
	}

	&__title {
		position: absolute;
		top: 0;
		width: calc(100% - 64px);
		margin: 0;
		padding: 8px 13px;
		left: 32px;
		font-size: 16px;
		line-height: 18px;
		color: $accent-color;
		font-weight: 500;
		text-align: center;
		background: $block-bg-main;
		border: 1px solid $text-third-color;
		border-radius: 5px;
	}

	&__text {
		margin: 0 0 32px;
		text-align: center;
	}

	&__price {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 32px;
	}

	&__price-new {
		margin: 0 8px;
		font-size: 30px;
		line-height: 112.5%;
		font-weight: 400;
	}

	&__price-old {
		margin: 0 8px;
		font-size: 16px;
		line-height: 18px;
		font-weight: 400;
		text-decoration: line-through;
	}

	&__list {
		margin: 0 0 32px;
		padding: 0;
		list-style: none;
		text-align: center;
	}

	&__item {
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__btn {
		width: 100%;
		margin-top: auto;
	}
}