.reviews-columns {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -30px -60px;
        padding: 0;
        list-style: none;

        @media (max-width: 1199px) {
        	margin-left: -15px;
        	margin-right: -15px;
        }

        @media (max-width: 991px) {
        	margin: 0 0 -48px;
        }
    }

    &__item {
        display: flex;
        width: 50%;
        padding: 0 30px 60px;

        @media (max-width: 1199px) {
        	padding-left: 15px;
        	padding-right: 15px;
        }

        @media (max-width: 991px) {
        	width: 100%;
        	padding: 0 0 48px;
        }
    }

    &__inner {
    	flex-grow: 2;
        display: flex;
        flex-direction: column;
    }

    &__message {
    	flex-grow: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #BDBDBD;
        border-radius: 5px;
        background: $bg-main-color;
        padding: 16px 16px 0;
        margin-bottom: 34px;

        &::before {
        	position:absolute;
            bottom: -5px;
            left: 35px;
            width: 40px;
            height: 25px;
            transform: rotate(-30deg) skewX(-45deg);
            background: $bg-main-color;
            z-index: -1;
            border: 1px solid #BDBDBD;
            content:'';

        }

        &::after {
        	position:absolute;
            left: 40px;
            bottom: -1px;
            width: 25px;
            height: 1px;
            background: $bg-main-color;
            content:'';
        }

        p {
            margin: 0 0 16px;
        }
    }

    &__bottom {
    	display: flex;
    }

    &__img {
    	position: relative;
    	width: 80px;
    	height: 80px;
    	margin-right: 16px;
    	border-radius: 50%;
    	overflow: hidden;

    	img {
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 100%;
    		height: 100%;
    		border-radius: 50%;
    		object-fit: cover;
    	}
    }

    &__caption {
    	margin: auto 0 0;
    	display: flex;
    	flex-direction: column;
    }

    &__name {
    	margin: 0 0 8px;
    	font-size: 20px;
    	line-height: 22.5px;
    	font-weight: 600;

    	@media (max-width: 767px) {
    		font-size: 18px;
    		line-height: 20px;
    	}
    }

    &__about {
    	font-size: 16px;
    	line-height: 18px;
    	font-weight: 300;

    	b {
    		font-weight: 300;
    		color: $accent-color;
    	}
    }
}