.product-item {

    &__inner {
        position: relative;
        display: flex;
        padding-left: 80px;

        @media (max-width: 991px) {
        	padding-left: 61.5px;
        }

        @media (max-width: 767px) {
        	padding-left: 0;
        }

        @media (max-width: 575px) {
        	flex-direction: column;
        	align-items: center;
        }
    }

    &__gallery {
        flex-shrink: 0;
        width: 440px;
        margin-right: 32px;

        @media (max-width: 991px) {
        	width: 308px;
        	margin-right: 16px;
        }

        @media (max-width: 767px) {
        	width: 40%;
        }

        @media (max-width: 575px) {
        	width: 100%;
        	max-width: 440px;
        	margin: 0 auto 20px;
        }
    }

    &__info {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__title {
        width: 100%;
        margin: 0 0 4px;
        font-size: 32px;
        line-height: 36px;
        color: $text-main-color;
        font-weight: 400;
        text-transform: uppercase;

        @media (max-width: 991px) {
            font-size: 28px;
            line-height: 112.5%;
            margin-bottom: 4px;
        }
    }

    &__article {
        margin: 0 0 32px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: $text-second-color;

        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
    }


    &__price {
        display: flex;
        flex-direction: row;
        margin-bottom: 32px;

        @media (max-width: 991px) {
        	margin-bottom: 0;
        }

        @media (max-width: 575px) {
        	margin-bottom: 32px;
        }
    }

    &__price--new {
        margin-right: 16px;
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 112.5%;
        font-weight: 400;
    }

    &__price--old {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 112.5%;
        color: $text-third-color;
        font-weight: 400;
        text-decoration: line-through;
    }

    &__properties {
        width: 295px;
        max-width: 100%;
        margin: 0 0 24px;
        padding: 0;
        list-style: none;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    &__properties-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 8px;

        span {
            position: relative;
            max-width: 40%;
            flex-shrink: 0;

            &:first-child {
                order: -1;
            }
        }

        &::before {
            position: relative;
            flex-grow: 2;
            top: -5px;
            width: 100%;
            height: 1px;
            background-color: $text-third-color;
            content: "";
        }
    }

    &__size {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        width: 295px;
        margin-bottom: 32px;
        max-width: 100%;

        @media (max-width: 991x) {
        	margin-bottom: 20px;
        }

        @media (max-width: 575px) {
        	width: 100%;
        }
    }

    &__size-title {
        margin: 0 16px 8px 0;
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        text-transform: uppercase;
    }

    &__size-link {
        margin: 0 0 8px;
        padding: 0;
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        color: $accent-color;
        border: none;
        background-color: transparent;
        text-decoration: 1px solid transparent;

        &:hover,
        &:focus {
            text-decoration: 1px underline $accent-color;
        }
    }

    &__btn {
        margin-top: auto;
        width: 160px;
        margin-bottom: 16px;

        @media (max-width: 575px) {
            width: 100%;
            margin-bottom: 4px;
        }
    }

    &__bottom {
        margin-top: 32px;
        padding-left: 80px;

        @media (max-width: 991px) {
        	padding-left: 61.5px;
        }

        @media (max-width: 767px) {
        	padding-left: 0;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -16px 0 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: flex;
        align-items: center;
        padding-right: 32px;
        margin-top: 16px;
    }

    &__icon {
        width: 36px;
        height: 36px;
        margin-right: 8px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__advantage {
        margin: 0;
        font-size: 20px;
        line-height: 112.5%;
        font-weight: 600;
    }

    &__desc {
        p {
            margin: 16px 0 0;
        }
    }

    &.swiper-slide {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    &.swiper-slide-active {
        opacity: 1;
        pointer-events: initial;
    }
}

.product-gallery {

    &__top {
        width: 100%;
        margin-bottom: 16px;
    }

    &__top-list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__top-item {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 136.5%;

        &.swiper-slide-active {
            z-index: 2;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 65px;

        @media (max-width: 991px) {
        	width: 45.5px;
        }

        @media (max-width: 767px) {
        	display: none;
        }
    }

    &__thumb-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__thumb-item {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 126%;
        margin-bottom: 12px;
        cursor: pointer;
        opacity: 0.4;
        transition: all 0.3s ease-in-out;

        &.swiper-slide-thumb-active {
            opacity: 1;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__pagination {
        display: none;
        position: absolute;
        z-index: 3;
        bottom: 20px;
        left: 20px;
        padding: 4px 10px 2px;
        background-color: rgba($white, 0.7);
        border-radius: 15px;
        font-size: 16px;
        line-height: 112.5%;
        font-weight: 300;
        letter-spacing: -0.09em;
        color: $text-main-color;

        @media (max-width: 767px) {
            display: block;
        }
    }
}

.product-item-slider {
	width: 100%;
	position: relative;
	overflow: hidden;

	&__top-list.active {
		height: auto !important;
	}

	&__thumb {
        display: none;
		color: $text-third-color;
		width: 180px;
		margin-left: auto;
		font-size: 16px;

        @media (max-width: 991px) {
            display: block;
        }

		button {
			border: none;
			color: $text-third-color;
			font-weight: 500;
            background: $bg-main-color;

			&::after {
				background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 8' xmlns='http://www.w3.org/2000/svg' fill='%23BDBDBD'%3E%3Cpath d='M10.59 0.589966L6 5.16997L1.41 0.589966L0 1.99997L6 7.99997L12 1.99997L10.59 0.589966Z'/%3E%3C/svg%3E%0A");
			}

			&::before {
				width: 45px;
			}
		}

		label {
			border: none;
		}

		ul {
			border: 1px solid $text-third-color;
			border-radius: 5px;
		}
	}
}