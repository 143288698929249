.contacts-section {
	position: relative;

	&__container {
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	&__map {
		position: absolute;
		top: 0;
		right: 20px;
		height: 100%;
		width: calc(50% - 20px);

		@media (max-width: 767px) {
			position: relative;
			top: 0;
			right: 0;
			width: 100%;
			height: 430px;
			margin-bottom: -60px;
		}
	}

	&__info {
		width: 50%;
		padding-right: 20px;

		@media (max-width: 767px) {
			width: 100%;
			padding-right: 0;
			padding-bottom: 40px;
		}
	}


	&__list {
		max-width: 325px;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 16px;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			fill: $text-main-color;
		}
	}

	&__text {
		margin: 0;
		font-weight: 300;
		text-decoration: underline solid transparent;
	}

	a:hover,
	a:focus {
		color: $accent-color;
	}

	.map-yandex {
		height: 100%;
	}
}