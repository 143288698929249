.prices {
	&__slider {
		margin-top: 60px;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: -40px -23px 0;
		list-style: none;

		@media (max-width: 991px) {
			margin-left: -20px;
			margin-right: -20px;
		}

		@media (max-width: 767px) {
			margin-left: -10px;
			margin-right: -10px;
		}

		&.swiper-wrapper {
			flex-wrap: nowrap;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__item {
		position: relative;
		flex-grow: 2;
		flex-shrink: 0;
		display: flex;
		width: calc(100%/3);
		padding: 0 23px;

		@media (max-width: 991px) {
			width: 50%;
			padding: 0 20px;
		}

		@media (max-width: 767px) {
			padding: 0 10px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}

		&.swiper-slide {
			padding: 0;

			@media (max-width: 991px) {
				width: 270px;
			}
		}
	}

	&__inner {
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		padding: 32px;
		border: 1px solid $text-third-color;
		background-color: $block-bg-main;
		border-radius: 5px;
		transition: all 0.3s ease-in-out;
		flex-direction: column;
		padding-top: 12px;
	}

	&__item:hover &__inner {
		background-color: $additional-color;
	}

	&__title {
		margin: 0 0 25px;
		font-size: 16px;
		line-height: 18px;
		color: $accent-color;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
	}

	&__price {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 25px;
	}

	&__price-new {
		margin: 0;
		font-size: 22px;
		line-height: 112.5%;
		font-weight: 300;
	}

	&__price-old {
		margin: 0 0 0 8px;
		font-size: 16px;
		line-height: 18px;
		font-weight: 300;
		color: $text-third-color;
		text-decoration: line-through;
	}

	&__btn {
		width: 100%;
		margin-top: auto;
	}
}