.video {
	position: relative;
	display: block;
	width: 100%;

	&::after {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($accent-color, 0.5);
		opacity: 0;
		content: '';
		transition: all 0.3s ease-in-out;
	}

	img {
		width: 100%;
		height: auto;
		transition: all 0.3s ease-in-out;
	}

	&__play {
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 56px;
		height: 56px;
		background-color: $white;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: 50%;
			left: calc(50% + 7px);
			transform: translate(-50%, -50%);
			border: 10px solid transparent;
			border-left: 13px solid red;
			transition: all 0.3s ease-in-out;
      		content: '';
		}

		&:hover:before {
			border-left-color: $black;
		}
	}

	&:hover,
	&:focus {
		img {
			opacity: 0.5;
		}

		&::after {
			opacity: 1;
		}
	}
}