//===========//
//MIXIN FONTS
//===========//
@mixin font-face($name, $certain-name, $path, $weight: null, $style: null, $exts: woff2 woff) {

  $src: local(quote($certain-name));

  $extmods: (
          eot: "?"
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-display: swap;
    font-weight: $weight;
    src: $src;
  }
}

//@include font-face("Proxima Nova", "ProximaNova-Light", "../fonts/ProximaNova/ProximaNova-Light", 300, normal);
//@include font-face("Proxima Nova", "ProximaNova-Regular", "../fonts/ProximaNova/ProximaNova-Regular", 400, normal);
//@include font-face("Proxima Nova", "ProximaNova-SemiBold", "../fonts/ProximaNova/ProximaNova-SemiBold", 600, normal);
//@include font-face("Proxima Nova", "ProximaNova-Bold", "../fonts/ProximaNova/ProximaNova-Bold", 700, normal);
//@include font-face("Proxima Nova", "ProximaNova-ExtraBold", "../fonts/ProximaNova/ProximaNova-ExtraBold", 800, normal);
//@include font-face("Proxima Nova", "ProximaNova-Black", "../fonts/ProximaNova/ProximaNova-Black", 900, normal);
//
@include font-face("Futura", "Futura-Bold", "../fonts/futura-bold", 700, normal);
@include font-face("Futura", "Futura-BookC", "../fonts/futura-book-c", 400, normal);
@include font-face("Futura", "Futura-DemiC", "../fonts/futura-demi-c", 600, normal);
@include font-face("Futura", "Futura-LightC", "../fonts/futura-light-c", 300, normal);
@include font-face("Futura", "Futura-MediumC", "../fonts/futura-medium-c", 500, normal);
@include font-face("rouble", "rouble", "../fonts/rouble-webfont", 400, normal);



