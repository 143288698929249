.map {
    &__map {
        position: relative;
        height: 350px;

        @media (max-width: 575px) {
            height: 560px;
        }
    }
}

.ymap-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
}


.map-yandex {
    position: relative;
    z-index: 7;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    >ymaps,
    >ymaps>ymaps {
        height: 100% !important;
    }
}

.loader {
    position: absolute;
    top: -100%;
    left: 0;

    width: 100%;
    height: 100%;

    transition: opacity .3s ease-in-out;
    background-color: rgba($black, 0.5);
    opacity: 0;

    overflow: hidden;
}

.loader.is-active {
    top: 0;
    opacity: 1;
}

.loader-default:after {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 100;

    width: 48px;
    height: 48px;

    border: solid 8px $accent-color;
    border-left-color: transparent;
    border-radius: 50%;

    animation: rotation 1s linear infinite;
    content: '';
}

@-webkit-keyframes rotation {
    from {
        transform: rotate(0);
    }

    to {

        transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0);
    }

    to {

        transform: rotate(359deg);
    }
}

@-webkit-keyframes blink {
    from {
        opacity: .5;
    }

    to {
        opacity: 1;
    }
}

@keyframes blink {
    from {
        opacity: .5;
    }

    to {
        opacity: 1;
    }
}