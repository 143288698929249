.faq {
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: num;
    }

    &__item {
        position: relative;

        &::before {
            position: absolute;
            left: 20px;
            top: 39px;
            font-size: 32px;
            line-height: 36px;
            font-weight: 400;
            color: $accent-color;
            content: counter(num) '.';
            counter-increment: num;

            @media (max-width: 767px) {
            	top: 26px;
            	left: 0;
                font-size: 28px;
                line-height: 21px;
            }
        }
    }

    &__btn {
        position: relative;
        width: 100%;
        padding: 40px 74px 38px 84px;
        margin: 0;
        font-size: 32px;
        line-height: 36px;
        font-weight: 400;
        text-align: center;
        color: $text-main-color;
        border: none;
        border-top: 1px solid #bdbdbd;
        background-color: transparent;

        @media (max-width: 767px) {
            padding: 24px 40px 22px 55px;
            font-size: 28px;
            line-height: 21px;
        }

        &::before,
        &::after {
            position: absolute;
            right: 20px;
            top: 50%;
            width: 34px;
            height: 1px;
            border-radius: 5px;
            background: $text-main-color;
            transform: translateY(-50%);
            content: "";
            transition: all 0.3s ease-in-out;

            @media (max-width: 767px) {
            	right: 0;
            	width: 24px;
            }
        }

        &::after {
            transform: translateY(-50%) rotate(90deg);
        }

        &:hover::before,
        &:focus::before,
        &:hover::after,
        &:focus::after {
            background: $accent-color;
        }

        &.active::after {
            transform: translateY(-50%) rotate(45deg);
        }

        &.active::before {
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    &__content {
        display: none;

        p {
            margin: 0 0 16px;

            b {
                font-weight: 300;
                color: $accent-color;
            }
        }
    }
}