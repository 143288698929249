.team {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px -30px;
		padding: 0;

		@media (max-width: 767px) {
			padding: 0 -8px -16px;
		}

		&.swiper-wrapper {
			flex-wrap: nowrap;
		}
	}

	&__item {
		width: 25%;
		padding: 0 15px 30px;

		@media (max-width: 767px) {
			padding: 0 8px 16px;

			&.swiper-slide {
				width: 220px;
			}
		}
	}

	&__inner {
		padding: 0 6px;
	}

	&__photo {
		position: relative;
		width: 100%;
		padding-bottom: 128%;
		margin-bottom: 24px;
		border-radius: 5px;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--round  &__photo {
		border-radius: 50%;
		padding-bottom: 100%;
	}

	&__name {
		margin: 0 0 12px;
		font-size: 20px;
		line-height: 22.5px;
		font-weight: 600;
		color: $text-main-color;
		text-align: center;
	}

	&__text {
		margin: 0;
		text-align: center;
	}

	.slider__nav {
		top: 30%;
	}
}