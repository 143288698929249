.about-section {
	&__content {
		display: flex;
		justify-content: space-between;
		max-width: 855px;
		margin: 60px auto 0;

		@media (max-width: 767px) {
			flex-direction: column-reverse;
			justify-content: flex-start;
			max-width: 390px;
			margin-top: 48px;
		}
	}

	&__image {
		position: relative;
		flex-shrink: 0;
		width: 390px;
		max-width: 50%;
		padding-bottom: 34%;
		overflow: hidden;

		@media (max-width: 767px) {
			max-width: 100%;
			margin-bottom: 32px;
			padding-bottom: 74.5%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__desc {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 390px;
		max-width: calc(50% - 16px);

		@media (max-width: 767px) {
			max-width: 100%;
		}

		p:not(.about-section__lead) {
			margin: 0;
		}
	}

	&__lead {
		margin: 0 0 32px;
		font-size: 22px;
		line-height: 25px;

		@media (max-width: 991px) {
			margin-bottom: 16px;
			font-size: 20px;
			line-height: 22.5px;
		}
	}
}