.products {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: -40px -20px 0;
		list-style: none;

		@media (max-width: 767px) {
			margin-left: -10px;
			margin-right: -10px;
			margin-top: -20px;
		}

		&.swiper-wrapper {
			flex-wrap: nowrap;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__item {
		flex-shrink: 0;
		display: flex;
		width: calc(100%/3);
		padding: 0 20px;
		margin-top: 40px;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 767px) {
			padding: 0 10px;
			margin-top: 20px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}

		&.swiper-slide {
			padding: 0;

			@media (max-width: 991px) {
				width: 320px;
			}
		}
	}
}

.product {
	flex-grow: 2;
	display: flex;
	flex-direction: column;

	&__image {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 66.5%;
		border-radius: 10px;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 10px;
		}
	}

	&__inner {
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		padding-top: 16px;
	}

	&__title {
		margin: 0 0 16px;
		text-align: center;
	}

	&__text {
		margin: 0 0 16px;
		text-align: center;
	}

	&__price {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 16px;
	}

	&__price-new {
		margin-right: 16px;
		font-size: 16px;
		line-height: 18px;
	}

	&__price-old {
		margin-right: 16px;
		color: $text-third-color;
		font-size: 16px;
		line-height: 18px;
	}

	&__not-stock {
		font-size: 12px;
		line-height: 18px;
		color: $text-red;
	}

	&__in-stock {
		font-size: 12px;
		line-height: 18px;
		color: $text-green;
	}

	&__btn {
		width: 100%;
		margin-top: auto;
	}
}
