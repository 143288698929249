.how-buy {
	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 0 38px;

		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}

	&__col {
		width: calc(50% - 15px);
		max-width: 480px;
		padding: 32px 40px 12px;
		margin-bottom: 30px;
		box-shadow: 6px 15px 31px rgba(147, 147, 147, 0.31);
		border-radius: 5px;
		background: $block-bg-main;

		@media (max-width: 991px) {
			padding: 24px 26px 8px;
		}

		@media (max-width: 767px) {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 48px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		padding: 0;
		list-style: none;

		@media (max-width: 991px) {
			margin: 0 -8px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(100%/3);
		padding: 0 10px 20px;

		@media (max-width: 991px) {
			padding: 0 8px 16px;
		}
	}

	&__title {
		margin: 0 0 24px;
		font-size: 20px;
		line-height: 112.5%;
		font-weight: 600;
	}

	&__icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 90px;
		height: 90px;
		margin-bottom: 16px;
		background: $block-bg-hover;
		border-radius: 50%;

		@media (max-width: 991px) {
			width: 80px;
			height: 80px;
		}

		img {
			max-width: calc(100% - 30px);
			max-height: calc(100% - 30px);
			width: auto;
			height: auto;
			object-fit: contain;
		}
	}

	&__text {
		margin: 0;
		text-align: center;
	}
}