.promo {
	position: relative;
	width: 100%;
	padding: 6% 0 9%;
	max-height: 577px;
	color: $text-white;
	background-color: $header-top-bg;

	@media (max-width: 991px) {
		padding: 0;
	}

	@media (max-width: 767px) {
		max-height: none;
	}

	&__container {
		@media (max-width: 767px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: calc(100vh - 64px);
			max-height: 690px;
			padding: 22% 10px 44%;
		}
	}

	> picture img,
	> img,
    &__img
    {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 390px;
		max-width: 100%;
		min-height: 256px;
		padding: 52px 0;

		@media (max-width: 767px) {
			flex-grow: 2;
			padding: 0;
		}
	}

	&__title {
		width: 100%;
		margin: 0 0 24px;
		font-size: 44px;
		line-height: 49px;
		font-weight: 600;
		color: inherit;
	}

	&__desc {
		width: 100%;
		margin: 0 0 24px;
		font-size: 24px;
		line-height: 112.5%;
		font-weight: 300;
	}

	&__btns {
		@media (max-width: 767px) {
			margin-top: auto;
		}
	}

	&__btn {
		margin: 12px 24px 0 0;
        display: inline-block;
		&:last-child {
			margin-right: 0;
		}
	}
}
