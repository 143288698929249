$black: #000;
$white: #fff;

// общие для всех тем
$header-top-bg: #1D2B47;
$bg-red: #FF3225;
$bg-yellow: #FFC226;
$bg-lightgrey: #F6F8FA;
$bg-section: #F6F8FA;

$image-bg: #141737;

// цвета для текста
$text-main-color: #303030;
$text-second-color: #6D7076;
$text-third-color: #BDBDBD;
$text-color-inversion: #ffffff;
$text-white: #ffffff;
$text-blue: #006BD0;
$text-red: #FF0000;
$text-green: #10D210;

// цвета для фона
$bg-main-color: #ffffff;
$bg-second-color: #F9FDFF;
$bg-overlay: rgba(255,255,255,0.6);
$bg-overlay-inv: rgba(0,0,0,0.6);

//цвета темы
$accent-color: #006BD0;
$additional-color: #E4F3F9;
$additional-color-2: #1D2B47;

$header-top-bg: #1D2B47;
$header-bg: #ffffff;
$menu-link-bg: #ffffff;
$menu-link-bg-hover: #F9FDFF;
$m-menu-bg: #F6F8FA;
$m-submenu-link-bg: #ffffff;

$block-bg-main: #ffffff;
$block-bg-hover: #F9FDFF;

$footer-bg-color: #F6F8FA;
$footer-second-bg: #1D2B47;
$footer-second-color: #ffffff;

$cookie-text-color: #ffffff;
$cookie-text-color-d: #1D2B47;
$cookie-bg: #ffffff;

// bg image

$select-arrow-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 7L6.57574 7.42426C6.81005 7.65858 7.18995 7.65858 7.42426 7.42426L7 7ZM0.575736 1.42426L6.57574 7.42426L7.42426 6.57574L1.42426 0.575736L0.575736 1.42426ZM7.42426 7.42426L13.4243 1.42426L12.5757 0.575736L6.57574 6.57574L7.42426 7.42426Z' fill='%23303030'/%3E%3C/svg%3E%0A");
$stages-arrow-right: url("data:image/svg+xml,%3Csvg viewBox='0 0 81 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M80.3536 3.64646C80.5488 3.84172 80.5488 4.1583 80.3536 4.35356L77.1716 7.53554C76.9763 7.7308 76.6597 7.7308 76.4645 7.53554C76.2692 7.34028 76.2692 7.0237 76.4645 6.82844L79.2929 4.00001L76.4645 1.17158C76.2692 0.976319 76.2692 0.659736 76.4645 0.464474C76.6597 0.269212 76.9763 0.269212 77.1716 0.464474L80.3536 3.64646ZM3.64262e-08 3.5L80 3.50001L80 4.50001L-3.64262e-08 4.5L3.64262e-08 3.5Z' fill='%23303030'/%3E%3C/svg%3E%0A");
