.stages {
	&__list {
		position: relative;
		margin: 0 auto;
		padding: 0;
		max-width: 790px;
		list-style-type: none; 
		counter-reset: num;

		&::after {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 2px;
			height: 100%;
			background-color: $accent-color;
			content: '';
		}
	}

	&__list + &__btn {
		display: none;

		@media (max-width: 575px) {
			display: block;
			width: 100%;
		}
	}

	&__item {
		position: relative;
		display: flex;
		width: calc(50% + 20px);

		&::after {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			z-index: 2;
			top: 50%;
			right: 0;
			width: 40px;
			height: 40px;
			padding-top: 3px;
			content: counter(num);
			counter-increment: num;
			border: 2px solid $accent-color;
			font-size: 24px;
			line-height: 1;
			background: $block-bg-main;
			border-radius: 50%;
			font-weight: 500;
			color: $additional-color-2;
		}

		&:nth-child(even) {
			margin-left: auto;
			flex-direction: row-reverse;

			&::after {
				left: 0;
			}
		}

		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: calc(100% - 85px);
		text-align: center;

		@media (max-width: 767px) {
			width: calc(100% - 55px);
		}
	}

	&__btn {
		margin-top: 20px;
		margin-left: auto;
	}

	&__inner &__btn {
		@media (max-width: 575px) {
			display: none;
		}
	}

	&__icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		margin-bottom: 20px;

		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			margin-bottom: 16px;
		}
	}

	&__icon img {
		max-width: 100%;
		height: auto;
		object-fit: contain;
	}

	&__title {
		margin: 0 0 12px;

		@media (max-width: 767px) {
			font-size: 18px;
			line-height: 20px;
		}
	}
}