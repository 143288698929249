.footer {
	padding: 50px 0 0;
	font-size: 16px;
	line-height: 18px;
	font-weight: 300;
	color: $text-main-color;
	background: $footer-bg-color;

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding-bottom: 10px;

		@media (max-width: 575px) {
			display: grid;
			grid-template-areas: 'a b' 'a c';
			grid-template-columns: 1fr 1fr;

		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@media (max-width: 767px) {
			grid-area: b;
			width: 50%;
			margin: 0;
		}

		@media (max-width: 575px) {
			width: 100%;
		}

		&--center {
			align-items: center;
			text-align: center;

			@media (max-width: 767px) {
				align-items: flex-start;
				text-align: left;
			}
		}

		&--right {
			align-items: flex-end;

			@media (max-width: 767px) {
				align-items: flex-start;
			}
		}

		&--menu {
			@media (max-width: 991px) {
				width: 100px;
				margin: 0 auto;
			}
			@media (max-width: 767px) {
				display: none;
			}
		}

		&:first-child {
			flex-shrink: 0;
			width: 190px;
			margin-right: 7.2%;

			@media (max-width: 991px) {
				width: 150px;
				margin-right: 5%;
				margin-left: 0;
			}

			@media (max-width: 767px) {
				width: 50%;
				padding-right: 25px;
				margin-right: 0;
			}

			@media (max-width: 575px) {
				width: 103px;
				padding-right: 0;
				grid-area: a;
				font-size: 12px;
				line-height: 14px;
			}
		}

		&:last-child {
			flex-shrink: 0;
			width: 195px;
			margin-left: 7.2%;

			@media (max-width: 991px) {
				margin-left: 5%;
			}

			@media (max-width: 767px) {
				width: 32px;
				margin-right: 0;
			}

			@media (max-width: 575px) {
				width: 100%;
				margin-left: 0;
				grid-area: c;
			}
		}
	}

	&__btn {
		padding: 12px 10px;
		margin-top: 30px;

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__bottom {
		padding: 35px 0;
		font-weight: 400;
		color: $text-second-color;

		@media (max-width: 991px) {
			padding: 24px 0;
		}

		@media (max-width: 575px) {
			font-size: 10px;
			line-height: 11px;
		}
	}

	&--multicolor &__bottom {
		color: $footer-second-color;
		background: $footer-second-bg;
	}

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: 'a b' 'c d';
			grid-column-gap: 10px;
		}
	}

	&__copyright {
		margin: 0;
		@media (max-width: 575px) {
			grid-area: a;
		}
	}

	&__link {
		display: inline-flex;
		justify-content: center;
		text-align: center;
		text-decoration: underline solid transparent;

		@media (max-width: 767px) {
			margin: 0;
			text-align: left;
			justify-content: flex-start;

			&:first-child {
				grid-area: b;
			}

			&:last-child {
				grid-area: d;
			}
		}

		&:hover,
		&:focus {
			color: $accent-color;
		}
	}

	&__dev {
		display: inline-flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		text-align: right;

		@media (max-width: 767px) {
			display: block;
			grid-area: c;
			text-align: left;
		}

		a {
			margin-left: 5px;
			text-decoration: underline solid transparent;
			color: $text-blue;

			&:hover,
			&:focus {
				color: $accent-color;
			}
		}
	}
}

.footer-menu {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;

	@media (max-width: 991px) {
		margin-right: 4%;
	}
	
	@media (max-width: 767px) {
		display: none;
	}

	&__item {
		width: calc(50% - 3.6%);
		margin-bottom: 20px;

		@media (max-width: 991px) {
			width: calc(100% - 7.2%);
			margin-right: 0;
		}

		&:nth-child(odd) {
			margin-right: 7.2%;

			@media (max-width: 991px) {
				margin-right: inherit;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		text-decoration: underline solid transparent;

		&:hover,
		&:focus {
			color: $accent-color;
		}
	}
}

.footer-contacts {
	width: 225px;
	margin: 0 0 0 7.2%;
	padding: 0;

	@media (max-width: 767px) {
		margin-left: 0;
	}

	@media (max-width: 575px) {
		width: 100%;
	}

	&__item {
		margin-bottom: 12px;

		@media (max-width: 575px) {
			margin-bottom: 0;

			&:not(:first-child) {
				display: none;
			}
		}
	}

	&__item,
	&__link {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		svg {
			fill: $text-main-color;
		}
	}

	&__link {
		text-decoration: underline solid transparent;

		&:hover,
		&:focus {
			color: $accent-color;

			svg {
				fill: $accent-color;
			}
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		padding: 0 4px;
		margin-right: 16px;

		@media (max-width: 575px) {
			display: none;
		}

		svg {
			width: 100%;
			object-fit: contain;
		}
	}
}