.about {
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $bg-overlay;
		content: "";
	}

	&.color-inversion {
		&::after {
			background: $bg-overlay-inv;
		}
	}

	&__content {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: flex-end;

		@media (max-width: 767px) {
			justify-content: flex-start;
			padding: 0 10px;
		}
	}

	&__desc {
		width: 390px;
		max-width: 100%;

		p {
			font-size: 22px;
			line-height: 25px;
			margin: 0 0 32px;
		}
	}

	&__title {
		margin: 20px 0 32px;

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}

	&__btn {
		min-width: 260px;
		margin-bottom: 20px;

		@media (max-width: 767px) {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			margin-top: 112px;
		}
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
	}
}

.about-video {
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__play {
		position: absolute;
		z-index: 3;
		bottom: 117px;
		left: calc((100vw - 1080px)/2 + 57px);
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
		border: none;
		background-color: $accent-color;
		border-radius: 50%;
		width: 56px;
		height: 56px;
		animation: pulse 1.5s ease-in-out infinite;

		@media (max-width: 1080px) {
			left: 57px;
		}

		@media (max-width: 767px) {
			left: auto;
			right: 57px;
			bottom: 56px;
		}

		@media (max-width: 575px) {
			bottom: 150px;
			left: 20px;
			transform: scale(0.5);
		}

		svg {
			width: 100%;
			height: 100%;
			fill: $white;
		}

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			content: '';
			animation: pulse 1.5s ease-in-out 0.5s infinite;
		}

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			content: '';
			animation: pulse 1.5s ease-in-out 1s infinite;
		}

		&:hover,
		&:focus {
			background-color: $black;
		}
	}
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($accent-color, 1);
  }
  100% {
  	box-shadow: 0 0 0 37px rgba($accent-color, 0);
  }
}

