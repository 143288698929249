.modal-cookie {
	display: block;
	position: fixed;
	z-index: 1000;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	padding: 32px 0;
	background: $cookie-bg;

	@media (max-width: 767px) {
		width: calc(100% - 20px);
		left: 10px;
		background: $text-main-color;
		border-radius: 5px 5px 0px 0px;
	}

	&__inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
	}

	&__content {
		max-width: 780px;
		padding-right: 30px;
		color: $cookie-text-color-d;

		@media (max-width: 767px) {
			color: $cookie-text-color;
		}
	}

	&__btn {
		flex-shrink: 0;
		background: $additional-color;
		border-color: $additional-color;
		color: $text-main-color;

		@media (max-width: 767px) {
			width: 100%;
			color: $accent-color;
			background: $bg-main-color;
			border-color: $bg-main-color;
		}
	}

	&__title {
		margin: 0 0 12px;
		font-size: 26px;
		line-height: 112.5%;
		font-weight: 600;

		@media (max-width: 767px) {
			margin-bottom: 16px;
			font-size: 30px;
			color: $additional-color;
		}
	}

	&__text {
		margin: 0 0 12px;
		font-size: 16px;
		line-height: 112.5%;
		font-weight: 300px;

		@media (max-width: 767px) {
			margin-bottom: 24px;
			color: $bg-main-color;
		}
	}
}