.partners {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: -15px;
		padding: 0;
		list-style: none;

		@media (max-width: 767px) {
			margin: -7.5px;
		}

		&.swiper-wrapper {
			flex-wrap: nowrap;
		}
	}

	&__item {
		display: flex;
		width: calc(100%/5);
		padding: 15px;

		@media (max-width: 991px) {
			width: 25%;
		}

		@media (max-width: 767px) {
			width: calc(100%/3);
			padding: 7.5px;

			&.swiper-slide {
				width: 197px;
			}
		}

		@media (max-width: 575px) {
			width: 50%;
		}
	}

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 2;
		height: 88px;
		padding: 6px;
		background-color: $white;
		box-shadow: 2px 3px 35px rgba(147, 147, 147, 0.29);
		border-radius: 5px;
		
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			object-fit: contain;
		}
	}

	&__item.swiper-slide &__logo {
		box-shadow: 2px 3px 15px rgba(147, 147, 147, 0.29);

		@media (max-width: 767px) {
			box-shadow: 2px 3px 7.5px rgba(147, 147, 147, 0.29);
		}
	}
}