.comparsion {
    &__slider {
        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__list {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: inherit
    }

    &__item {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-shrink: 0;
        background-color: inherit
    }

    &__inner {
        position: relative;
        width: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        &::after {
        	position: absolute;
            display: inline-block;
            vertical-align: baseline;
            text-align: center;
            right: 12px;
            bottom: 12px;
            width: 88px;
            padding: 4px 0;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            content: 'После';
        }
    }

    img {
        max-width: none;
        object-position: left;
    }

    &__nav {
        @media (max-width: 991px) {
            display: block;

            &--prev {
                left: 0;
            }

            &--next {
                right: 0;
            }
        }
    }
}