*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: "Futura", "Arial", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.5%;
    color: $text-main-color;
    background-color: $bg-main-color;

    &.lock {
        position: relative;
        overflow: hidden;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

svg {
    display: block;
    transition: 0.3s ease-in-out;
}

.icon {
    width: 1em;
    height: 1em;
}

a {
    color: inherit;
    transition: 0.3s ease-in-out;

    &:hover {
        text-decoration: underline solid transparent;
        transition: 0.3s ease-in-out;
    }
}

button {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

*:focus {
    outline: none;
}

/*=== GRID ===*/

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

footer {
    margin-top: auto;
}

.container {
    max-width: 1080px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    &--small {
        max-width: 650px;
    }

    @media (max-width: 767px) {
        padding: 0 10px;
    }
}

.section {
    padding: 80px 0;
    background: $bg-main-color;

    @media (max-width: 767px) {
        padding: 60px 0;
    }

    &--bg {
        position: relative;
        background: $bg-second-color;

        .container {
            position: relative;
            z-index: 2;
        }
    }

    &--bg>picture img,
    &--bg>img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.section+.section:not(.section--bg) {
    padding-top: 0;
}

.section--bg+.section:not(.section--bg) {
    padding-top: 80px;

    @media (max-width: 767px) {
        padding-top: 60px;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden
}

/*=== CONTENT ===*/

h1 {
    width: 100%;
    font-weight: 600;
    font-size: 44px;
    line-height: 49px;
    color: $text-main-color;

    @media (max-width: 767px) {
        font-size: 36px;
    }
}

h2 {
    font-size: 36px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;

    @media (max-width: 767px) {
        font-size: 30px;
    }
}

h3 {
    font-size: 22px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;
}

h4 {
    font-size: 20px;
    line-height: 22.5px;
    font-weight: 600;
    color: $text-main-color;
}

h5 {
    font-size: 18px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;
}

h6 {
    font-size: 16px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;
}

.h1 {
    width: 100%;
    font-weight: 600;
    font-size: 44px;
    line-height: 49px;
    color: $text-main-color;

    @media (max-width: 767px) {
        font-size: 36px;
    }
}

.h2 {
    width: 100%;
    font-size: 36px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;

    @media (max-width: 767px) {
        font-size: 30px;
        margin-bottom: 40px;
    }
}

.section .container h2,
.section .container .h2 {
    max-width: 650px;
    margin: 0 auto 60px;
    text-align: center;

    @media (max-width: 767px) {
        margin-bottom: 40px;
    }
}

.h3 {
    font-size: 32px;
    line-height: 112.5%;
    font-weight: 600;

    @media (max-width: 767px) {
        font-size: 22.5px;
    }
}

.h4 {
    font-size: 20px;
    line-height: 22.5px;
    font-weight: 600;
    color: $text-main-color;
}

.h5 {
    font-size: 18px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;
}

.h6 {
    font-size: 16px;
    line-height: 112.5%;
    font-weight: 600;
    color: $text-main-color;
}

.color-inversion h2,
.color-inversion h3,
.color-inversion h4,
.color-inversion h5,
.color-inversion h6,
.color-inversion .h2,
.color-inversion .h3,
.color-inversion .h4,
.color-inversion .h5,
.color-inversion .h6 {
    color: $text-color-inversion;
}

.subtitle {
    max-width: 650px;
    width: 100%;
    margin: 0 auto 50px;
    font-size: 22px;
    line-height: 25px;
    font-weight: 300;
    text-align: center;

    @media (max-width: 767px) {
        margin-bottom: 45px;
        font-size: 20px;
    }
}

.h2+.subtitle {
    margin-top: -44px;

    @media (max-width: 767px) {
        margin-top: -24px;
    }
}

.btn {
    display: inline-block;
    vertical-align: baseline;
    padding: 11px 16px;
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    color: $white;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    border: 1px solid $accent-color;
    border-radius: 5px;
    background-color: $accent-color;

    &--light {
        background-color: transparent;
    }

    &--white {
        background: $block-bg-main;
        color: $accent-color;

        &:hover,
        &:focus {
            color: $white;
        }
    }

    &:hover,
    &:focus {
        background-color: rgba($accent-color, 0.7);
        border-color: transparent;
        text-decoration: none;
    }
}

img.lazyload {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

img.lazyloaded {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.slider {
    position: relative;
    width: 100%;

    @media (max-width: 767px) {
        width: calc(100% + 20px);

        &--center-m {
            width: calc(100% + 40px);
            left: -20px;
        }

        &--single {
            width: 100%;
        }
    }

    @media (max-width: 767px) {
        width: calc(100% + 10px);

        &--center-m {
            width: calc(100% + 20px);
            left: -10px;
        }

        &--single {
            width: 100%;
        }
    }

    &__container {
        position: relative;
        overflow: hidden;
    }

    &--single &__container {
        overflow: visible;
    }

    .swiper-wrapper {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &--autoheight .swiper-wrapper {
        align-items: flex-start;
    }

    .swiper-slide {
        flex-shrink: 0;
    }

    &__nav {
        position: absolute;
        z-index: 2;
        top: calc(50% - 16px);
        width: 32px;
        height: 32px;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        border-radius: 50%;

        @media (max-width: 991px) {
            display: none;
        }

        &--prev {
            right: calc(100% + 20px);

            @media (max-width: 1199px) {
                left: -20px;
            }
        }

        &--next {
            left: calc(100% + 20px);
            transform: scale(-1);

            @media (max-width: 1199px) {
                left: auto;
                right: -20px;
            }
        }

        &.swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
        }

        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: $text-third-color;
        }

        &:hover,
        &:focus {
            background-color: $accent-color;

            svg {
                fill: $white;
            }
        }
    }
}

.labels {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 12px;

    &__item {
        min-width: 36px;
        padding: 3px 5px 0;
        margin-bottom: 12px;
        font-size: 11px;
        line-height: 15px;
        text-transform: uppercase;
        border-radius: 5px;
        text-align: center;

        &--new {
            color: $white;
            background-color: $bg-yellow;
        }

        &--sale {
            color: $white;
            background-color: $bg-red;
        }

        &--discount {
            margin-top: auto;
            padding: 5px 8px 3px;
            font-size: 14px;
            line-height: 15px;
            color: $bg-red;
            font-weight: 600;
            background-color: $white;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.select {
    position: relative;
    z-index: 3;
    width: 100%;
    font-size: 16px;
    line-height: 112.5%;
    font-weight: 300;
    background: $block-bg-main;

    &__btn {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 10px 48px 10px 16px;
        font-size: 16px;
        line-height: 112.5%;
        font-weight: 300;
        text-align: left;
        white-space: nowrap;
        border: none;
        background-color: transparent;
        height: 38px;
        overflow: hidden;
        color: $text-main-color;
        border: 1px solid $text-second-color;
        border-radius: 3px 3px 0 0;
        white-space: nowrap;
        text-overflow: ellipsis;

        &::after {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 8px;
            background-image: $select-arrow-image;
            background-repeat: no-repeat;
            background-size: contain;
            transition: all 0.3s ease-in-out;
            content: '';
        }

        &:hover,
        &:focus {
            color: $accent-color;
        }
    }

    &.active &__btn::after {
        transform: translateY(-50%) scaleY(-1);
    }

    &__list {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        width: 100%;
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        border: 1px solid $text-second-color;
        border-top: none;
        border-radius: 0 0 3px 3px;

    }

    &__option {

        label {
            display: block;
            width: 100%;
            padding: 10px 48px 10px 16px;
            white-space: nowrap;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            border-top: 1px solid $text-second-color;
            background: $block-bg-main;

            &:hover,
            &:focus {
                background: $block-bg-hover;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        input:checked+label {
            display: none;
        }

        input:disabled+label {
            color: $text-third-color;
            pointer-events: none;
        }
    }
}

.show-text {
    transition: all 0.3s ease-in-out;

    &__content {
        transition: all 0.3s ease-in-out;
    }

    &.show &__content {
        display: block !important;
    }

    &__btn {
        position: relative;
        padding: 0 32px 0 0;
        margin: 0 0 0 auto;
        width: 100%;
        text-align: right;
        border: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $text-third-color;
        background-color: transparent;

        svg {
            position: absolute;
            top: calc(50% - 3.5px);
            right: 6px;
            widows: 12px;
            height: 7px;
            fill: $text-third-color;
        }

        &.active svg {
            transform: scaleY(-1);
        }

        &:hover,
        &:focus {
            color: $accent-color;

            svg {
                fill: $accent-color;
            }
        }
    }
}

.color-inversion {
    color: $text-color-inversion;
    background: $text-main-color;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @media (max-width: 767px) {
        margin: 0 -10px;
    }
}

.col-md-6 {
    width: 100%;
    padding: 0 10px;
}

@media (min-width: 768px) {
    .col-md-6 {
        width: 50%;
        padding: 0 15px;
    }
}

.table-container {
    position: relative;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 15px;
    margin-bottom: -15px;

    &.scroll {
        padding-bottom: 36px;

        &::after {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 8px;
            width: 80px;
            background-image: $stages-arrow-right;
            background-repeat: no-repeat;
            background-position: right;
            background-size: auto 100%;
            content: "";
        }
    }

    @media (max-width: 767px) {
        width: calc(100% + 10px);
    }
}

.typography table {
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    border-collapse: collapse;
    border: 0;

    th {
        padding: 22px 10px;
        color: $accent-color;
        background: $block-bg-hover;
        font-size: 20px;
        font-weight: 600;
        line-height: 112.5%;
        text-align: center;
        min-width: 200px;
        width: auto
    }

    td {
        padding: 22px 10px;
        font-size: 20px;
        font-weight: 300;
        line-height: 112.5%;
        text-align: center;
        min-width: 200px;
        width: auto;
        background: $block-bg-main;
    }

    tbody tr:nth-child(even) td {
        background: $block-bg-hover;
    }
}

.check-list {
    padding: 0;
    margin: 0 0 28px;
    list-style: none;

    li {
        position: relative;
        margin: 0 0 14px;
        padding: 0 0 0 28px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C12.3871 0 16 3.57 16 8C16 12.3871 12.3871 16 8 16C3.57 16 0 12.3871 0 8C0 3.57 3.57 0 8 0ZM8 0.860257C4.04286 0.860257 0.860286 4.04311 0.860286 7.99997C0.860286 11.914 4.04314 15.1397 8 15.1397C11.914 15.1397 15.1397 11.914 15.1397 7.99997C15.1397 4.04283 11.914 0.860257 8 0.860257Z' fill='%23008B6A'/%3E%3Cpath d='M5.2474 7.52672L7.0108 9.3332L10.7528 5.37606C10.8818 5.24704 11.1399 5.24704 11.269 5.37606L11.7851 5.84917C11.9572 6.02127 11.9572 6.23623 11.7851 6.36535L7.312 11.1396C7.1399 11.2686 6.92494 11.3117 6.75285 11.1396L4.21525 8.55894C4.04316 8.42993 4.04316 8.21486 4.21525 8.04277L4.73143 7.5266C4.86044 7.39759 5.07551 7.39759 5.2476 7.5266L5.2474 7.52672Z' fill='%23008B6A'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
        }
    }
}

.typography>*:last-child {
    margin-bottom: 0;
}

.typography>*:first-child {
    margin-top: 0;
}

.typography ul {
    padding-left: 25px;
    list-style: none;

    >li {
        position: relative;
        margin-bottom: 5px;

        &::before {
            position: absolute;
            top: 4px;
            left: -15px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $accent-color;
            content: "";
        }
    }
}

.typography ul ul,
.typography ul ol,
.typography ol ul,
.typography ol ol {
    margin-top: 5px;
}

.typography ol {
    padding-left: 25px;
    list-style-type: none;
    counter-reset: num;

    >li {
        position: relative;
        margin-bottom: 5px;

        &::before {
            content: counter(num) '.';
            counter-increment: num;
            display: inline-block;
            position: absolute;
            top: 0px;
            left: -26px;
            width: 20px;
            color: $accent-color;
            font-weight: 400;
            text-align: right;
        }
    }
}

.typography button {
    margin-bottom: 16px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.rouble {
  font-family:'rouble';
}

// сетка

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @media (max-width: 767px) {
        margin: 0 -10px;
    }
}

.col {
    padding: 0 15px;
    width: 100%;

    @media (max-width: 767px) {
        padding: 0 10px;
    }
}

.col-xs-12 {
    width: calc(100%/12);
}

.col-xs-11 {
    width: calc(100%/12 * 11);
}

.col-xs-10 {
    width: calc(100%/12 * 10);
}

.col-xs-9 {
    width: calc(100%/12 * 9);
}

.col-xs-8 {
    width: calc(100%/12 * 8);
}

.col-xs-7 {
    width: calc(100%/12 * 7);
}

.col-xs-6 {
    width: calc(100%/12 * 6);
}

.col-xs-5 {
    width: calc(100%/12 * 5);
}

.col-xs-4 {
    width: calc(100%/12 * 4);
}

.col-xs-3 {
    width: calc(100%/12 * 3);
}

.col-xs-2 {
    width: calc(100%/12 * 2);
}

@media (min-width: 576px) {
    .col-sm-12 {
        width: calc(100%/12);
    }

    .col-sm-11 {
        width: calc(100%/12 * 11);
    }

    .col-sm-10 {
        width: calc(100%/12 * 10);
    }

    .col-sm-9 {
        width: calc(100%/12 * 9);
    }

    .col-sm-8 {
        width: calc(100%/12 * 8);
    }

    .col-sm-7 {
        width: calc(100%/12 * 7);
    }

    .col-sm-6 {
        width: calc(100%/12 * 6);
    }

    .col-sm-5 {
        width: calc(100%/12 * 5);
    }

    .col-sm-4 {
        width: calc(100%/12 * 4);
    }

    .col-sm-3 {
        width: calc(100%/12 * 3);
    }

    .col-sm-2 {
        width: calc(100%/12 * 2);
    }
}

@media (min-width: 768px) {
    .col-md-12 {
        width: calc(100%/12);
    }

    .col-md-11 {
        width: calc(100%/12 * 11);
    }

    .col-md-10 {
        width: calc(100%/12 * 10);
    }

    .col-md-9 {
        width: calc(100%/12 * 9);
    }

    .col-md-8 {
        width: calc(100%/12 * 8);
    }

    .col-md-7 {
        width: calc(100%/12 * 7);
    }

    .col-md-6 {
        width: calc(100%/12 * 6);
    }

    .col-md-5 {
        width: calc(100%/12 * 5);
    }

    .col-md-4 {
        width: calc(100%/12 * 4);
    }

    .col-md-3 {
        width: calc(100%/12 * 3);
    }

    .col-md-2 {
        width: calc(100%/12 * 2);
    }
}

@media (min-width: 992px) {
    .col-lg-12 {
        width: calc(100%/12);
    }

    .col-lg-11 {
        width: calc(100%/12 * 11);
    }

    .col-lg-10 {
        width: calc(100%/12 * 10);
    }

    .col-lg-9 {
        width: calc(100%/12 * 9);
    }

    .col-lg-8 {
        width: calc(100%/12 * 8);
    }

    .col-lg-7 {
        width: calc(100%/12 * 7);
    }

    .col-lg-6 {
        width: calc(100%/12 * 6);
    }

    .col-lg-5 {
        width: calc(100%/12 * 5);
    }

    .col-lg-4 {
        width: calc(100%/12 * 4);
    }

    .col-lg-3 {
        width: calc(100%/12 * 3);
    }

    .col-lg-2 {
        width: calc(100%/12 * 2);
    }
}

@media (min-width: 1200px) {
    .col-xl-12 {
        width: calc(100%/12);
    }

    .col-xl-11 {
        width: calc(100%/12 * 11);
    }

    .col-xl-10 {
        width: calc(100%/12 * 10);
    }

    .col-xl-9 {
        width: calc(100%/12 * 9);
    }

    .col-xl-8 {
        width: calc(100%/12 * 8);
    }

    .col-xl-7 {
        width: calc(100%/12 * 7);
    }

    .col-xl-6 {
        width: calc(100%/12 * 6);
    }

    .col-xl-5 {
        width: calc(100%/12 * 5);
    }

    .col-xl-4 {
        width: calc(100%/12 * 4);
    }

    .col-xl-3 {
        width: calc(100%/12 * 3);
    }

    .col-xl-2 {
        width: calc(100%/12 * 2);
    }
}

@media (min-width: 1400px) {
    .col-xxl-12 {
        width: calc(100%/12);
    }

    .col-xxl-11 {
        width: calc(100%/12 * 11);
    }

    .col-xxl-10 {
        width: calc(100%/12 * 10);
    }

    .col-xxl-9 {
        width: calc(100%/12 * 9);
    }

    .col-xxl-8 {
        width: calc(100%/12 * 8);
    }

    .col-xxl-7 {
        width: calc(100%/12 * 7);
    }

    .col-xxl-6 {
        width: calc(100%/12 * 6);
    }

    .col-xxl-5 {
        width: calc(100%/12 * 5);
    }

    .col-xxl-4 {
        width: calc(100%/12 * 4);
    }

    .col-xxl-3 {
        width: calc(100%/12 * 3);
    }

    .col-xxl-2 {
        width: calc(100%/12 * 2);
    }
}