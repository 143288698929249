.reviews {
	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: flex;
		align-items: flex-start;
		width: 100%;
		padding: 40px;
		margin-top: 48px;
		background-color: $additional-color;
		border-radius: 10px;

		@media (max-width: 767px) {
			padding: 20px;
		}

		@media (max-width: 575px) {
			flex-wrap: wrap;
		}
	}

	&__image {
		position: relative;
		flex-shrink: 0;
		width: 378px;
		max-width: calc(50% - 40px);
		margin-right: 40px;
		padding-bottom: 24.2%;

		@media (max-width: 767px) {
			margin-right: 20px;
			max-width: calc(50% - 20px);
		}

		@media (max-width: 575px) {
			width: 100%;
			max-width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
			padding-bottom: 62%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 20px;
	}

	&__caption {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0;
	}

	&__name {
		margin: 0 0 8px;
		font-size: 24px;
		line-height: 27px;
		font-weight: 400;

		@media (max-width: 767px) {
			font-size: 18px;
			line-height: 112.5%;
		}
	}

	&__about {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;

		b {
			font-weight: 400;
			color: $accent-color;
		}
	}

	&__date {
		flex-shrink: 0;
		white-space: nowrap;
		margin-left: 12px;
	}

	&__content {
		p {
			margin: 0 0 7px;
		}
	}
}