.goods {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 40px -12px 0;
		list-style: none;

		@media (max-width: 767px) {
			margin-left: -10px;
			margin-right: -10px;
		}

		&.swiper-wrapper {
			flex-wrap: nowrap;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__item {
		flex-shrink: 0;
		display: flex;
		width: calc(100%/5);
		padding: 0 12px;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 767px) {
			padding: 0 10px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}

		&.swiper-slide {
			padding: 0;

			@media (max-width: 991px) {
				width: 190px;
			}
		}
	}
}

.good {
	flex-grow: 2;
	display: flex;
	flex-direction: column;

	&__image {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 140%;
		border-radius: 5px;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 10px;
		}
	}

	&__inner {
		position: relative;
		flex-grow: 2;
		display: flex;
		flex-wrap: wrap;
		padding-top: 12px;
		padding-bottom: 24px;
	}

	&__title {
		width: 50%;
		margin: 0 0 12px;
		padding-right: 15px;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: $text-main-color;
	}

	&__price {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 50%;
		margin-bottom: 12px;
	}

	&__price-new {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		text-align: right;
	}

	&__price-old {
		color: $text-third-color;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		text-align: right;
	}

	&__not-stock {
		position: absolute;
		top: calc(100% - 20px);
		left: 0;
		width: 100%;
		font-size: 12px;
		line-height: 18px;
		color: $text-red;
	}

	&__in-stock {
		position: absolute;
		top: calc(100% - 20px);
		left: 0;
		width: 100%;
		font-size: 12px;
		line-height: 18px;
		color: $text-green;
	}

	&__btn {
		width: 100%;
		margin-top: auto;
	}
}