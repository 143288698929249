.mobile-menu {
	display: none;
	position: fixed;
	top: 100%;
	left: 0;
	z-index: 1001;
	background: $m-menu-bg;
	color: $text-main-color;
	width: 100%;
	height: calc(100vh - 64px);
	overflow-y: auto;
	overflow-x: hidden;

	&__container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__list &__list &__link {
		padding-left: 48px;
	}

	&__item {
		position: relative;
	}

	&__list &__list &__item {
		background: $m-submenu-link-bg;
	}

	&__link {
		position: relative;
		display: block;
		padding-bottom: 1px;
		text-decoration: none;
		max-width: 1080px;
		width: 100%;
		padding: 14px 20px 15px 20px;
		margin: 0 auto;
		font-size: 16px;
		line-height: 18px;
		color: $text-main-color;
		font-weight: 500;

		@media (max-width: 767px) {
			padding-left: 10px;
			padding-right: 10px;
		}

		&::before {
			position: absolute;
			top: calc(100% - 1px);
			left: -50vw;
			width: 200vw;
			height: 1px;
			background-color: #BDBDBD;
			content: "";
		}

		&:hover,
		&:focus {
			text-decoration: none;
			color: $accent-color;
		}
	}

	.submenu > &__list {
		display: none;
	} 

	.submenu > &__link {
		&:hover,
		&:focus {
			color: $text-main-color;

			span::after {
				border-color: $accent-color;
			}
		}

		span {
			display: inline-block;
			vertical-align: baseline;
			transition: all 0.3s ease-in-out;

			&::after {
				position: relative;
				display: inline-block;
				vertical-align: baseline;
				width: 9px;
				height: 9px;
				margin-bottom: 2px;
				margin-left: 16px;
				border-bottom: 1px solid $text-main-color;
				border-right: 1px solid $text-main-color;
				transform: rotate(45deg);
				transition: all 0.3s ease-in-out;
				content: '';
			}

			&:hover,
			&:focus {
				color: $accent-color;

				&::after {
					border-color: $text-main-color;
				}
			}
		}
	}

	&__item.active &__link > span::after {
		top: 5px;
		transform: rotate(45deg) scale(-1);
	}

	&__bottom {
		padding: 60px 0 56px;
		margin-top: auto;
	}

	&__contacts {
		margin: 0 0 40px;
		padding: 0;
		list-style: none;
	}

	&__contact {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		&-icon {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
			margin-right: 12px;
			flex-shrink: 0;

			svg {
				width: 100%;
				height: 100%;
				fill: $text-main-color;
				object-fit: contain;
			}
		}

		&-text {
			margin: 0;
		}
	}

}