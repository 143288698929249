.adv-video-block {
	position: relative;
	display: flex;
	max-width: 900px;
	margin: 0 auto;

	@media (max-width: 575px) {
		flex-direction: column;
		padding-bottom: 65px;
	}

	&__video {
		width: 50%;

		@media (max-width: 575px) {

			width: 100%;
		}

	}

	&__desc {
		width: 50%;
		padding-left: 30px;

		@media (max-width: 575px) {
			order: -1;
			width: 100%;
			padding-left: 0;
		}
	}

	&__title {
		margin: 0 0 24px;
		font-size: 32px;
		line-height: 112.5%;
		color: $text-main-color;

		@media (max-width: 767px) {
			font-size: 18px;
			line-height: 21px;
			margin-bottom: 12px;
		}
	}

	&__text {
		margin: 0 0 24px;

		@media (max-width: 767px) {
			margin-bottom: 12px;
		}
	}

	&__btn {

		@media (max-width: 575px) {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
}