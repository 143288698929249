.features {

	&__list {
		max-width: 650px;
		width: 100%;
		margin: 0 auto;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: flex;
		margin-bottom: 48px;

		@media (max-width: 575px) {
			margin-bottom: 30px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		p {
			margin-top: 0;
			line-height: 18px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__icon {
		flex-shrink: 0;
		width: 68px;
		margin-right: 20px;

		@media (max-width: 575px) {
			width: 48px;
			margin-right: 16px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	&__title {
		margin: 0 0 16px;
		color: $text-main-color;

		@media (max-width: 575px) {
			margin-bottom: 12px;
		}
	}

	&__text {
		margin: 0;
		line-height: 18px;
	}
}