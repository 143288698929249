.promotion {
    position: relative;

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $bg-overlay;
        content: "";
    }

    &.color-inversion {
        &::before {
            background: $bg-overlay-inv;
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        max-width: 100%;
        width: 460px;
        padding: 84px 0 44px;

        @media (max-width: 1199px) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @media (max-width: 767px) {
            max-width: 235px;
        }

        p {
            margin: 0 0 60px;
            font-size: 36px;
            line-height: 40.5px;
            font-weight: 600;

            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 28px;
            }
        }
    }

    &__title {
        margin: 0 0 40px;
        font-size: 44px;
        line-height: 49.5px;
        font-weight: 600;

        @media (max-width: 767px) {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 16px;
        }
    }

    &__btn {
        min-width: 260px;

        @media (max-width: 767px) {
            min-width: 181px;
        }
    }
}