.map-section {

	&__map {
		position: absolute;
		top: 0;
		left: 20px;
		height: 100%;
		width: calc(100% - 40px);

		@media (max-width: 767px) {
			position: relative;
			left: 0;
			top: 0;
			height: 430px;
			width: 100%;
			margin-top: -20%;
		}
	}

	&__container {
		position: relative;
		z-index: initial;
		background: inherit;
	}

	&__info {
		position: relative;
		z-index: 8;
		width: 390px;
		margin-left: 40px;
		max-width: 100%;
		padding: 32px 40px;
		background: $block-bg-main;

		@media (max-width: 991px) {
			margin-left: 20px;
			width: calc(50% - 40px);
			padding: 32px;
		}

		@media (max-width: 767px) {
			width: calc(100% - 40px);
			box-shadow: 1px 1px 16px rgba(147, 147, 147, 0.19);
		}

		h2 {
			text-align: left;
		}
	}


	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 16px;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			fill: $text-main-color;
		}
	}

	&__text {
		margin: 0;
		font-weight: 300;
		text-decoration: underline solid transparent;
	}

	a:hover,
	a:focus {
		color: $accent-color;
	}

	.map-yandex {
		height: 100%;
	}
}