.feedback-block {
	position: relative;
	padding-top: 80px;
	padding-bottom: 80px;
	background-color: $white;

	@media (max-width: 767px) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	>picture img,
	>img {
		position: absolute;
		left: auto;
		right: 0;
		top: 0;
		height: 100%;
		width: 50%;
		object-fit: cover;

		@media (max-width: 767px) {
			position: static;
			width: 100%;
			margin-bottom: -50px;
			margin-top: 0;
		}
	}

	&__form {
		width: calc(1040px/2);
		padding-right: 48px;

		@media (max-width: 1080px) {
			width: 50%;
			padding-right: 20px;
		}

		@media (max-width: 767px) {
			width: 100%;
			padding-right: 0;
		}
	}

	h2 {
		text-align: left;

		@media (max-width: 767px) {
			color: $white;
			margin-top: -70px;
			transform: translateY(-100%);
			text-align: center;
		}
	}
}