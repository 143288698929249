.adv-image-block {
    margin-bottom: -80px;

    @media (max-width: 767px) {
        margin-bottom: -60px;
    }

    &__title {
        max-width: 500px;
        margin-bottom: 5px;
    }

    &__content {
        position: relative;
        padding: 50px 0 170px;

        @media (max-width: 575px) {
            padding-bottom: 110px;
        }

        >picture img,
        >img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 100%;
            object-fit: contain;
        }

        p {
            margin: auto 0 0;
            font-size: 20px;
            line-height: 22px;

            @media (max-width: 991px) {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    &__list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 -80px;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: flex;
        width: 50%;
        margin-bottom: 70px;

        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
    }

    &__item:nth-child(even) {
        justify-content: flex-end;
    }

    &__inner {
        display: flex;
        width: 260px;
        max-width: 50%;
        padding-bottom: 15px;
        border-bottom: 1px solid $accent-color;

        @media (max-width: 575px) {
            padding-bottom: 10px;
        }
    }

    &__item:nth-child(odd) &__inner {
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;

        @media (max-width: 575px) {
            justify-content: flex-start;
            text-align: left;
        }
    }

    &__item:nth-child(even) &__inner {
        @media (max-width: 575px) {
            justify-content: flex-end;
            text-align: right;
        }
    }

    &__text {
        margin: auto 0 0;
        font-size: 20px;
        line-height: 22px;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 18px;
        }
    }
}