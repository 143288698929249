.advantages-section {

	&__list {
		max-width: 780px;
		width: 100%;
		margin:  0 auto;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: flex;
		margin-bottom: 60px;

		@media (max-width: 575px) {
			flex-direction: column;
			align-items: center;
			margin-bottom: 45px;
		}

		&:nth-child(even) {
			flex-direction: row-reverse;

			@media (max-width: 575px) {
				flex-direction: column;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		p {
			margin-top: 0;
			line-height: 18px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__image {
		flex-shrink: 0;
		width: 50%;
		margin-right: 65px;


		@media (max-width: 767px) {
			margin-right: 30px;
		}

		@media (max-width: 575px) {
			width: 100%;
			max-width: 390px;
			margin: 0 auto 24px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
		}
	}

	&__item:nth-child(even) &__image {
		margin-right: 0;
		margin-left: 65px;

		@media (max-width: 767px) {
			margin-left: 30px;

			@media (max-width: 575px) {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__title {
		margin: 0 0 20px;
	}

	&__text {
		margin: 0;
		line-height: 18px;
	}
}