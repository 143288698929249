.why-we-section {
	
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: -40px -20px 0;
		padding: 0;
		list-style: none;

		@media (max-width: 767px) {
			margin: -40px -10px 0;
		}
	}

	&__item {
		width: 50%;
		padding: 0 20px;
		margin-top: 40px;

		@media (max-width: 767px) {
			padding: 0 10px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}
	}

	&__inner {
		padding: 30px 28px;
		background: $block-bg-main;
		box-shadow: 0px 0px 10px rgba(184, 184, 184, 0.3);
		border-radius: 5px;
		min-height: 100%;

		ul {
			margin: 0;
			padding-left: 25px;
			list-style: disc;
		}
	}

	&__title {
		margin: 0 0 20px;
		color: $accent-color;
	}
}