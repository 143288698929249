.advantages-block {
	&__list {
		display: grid;
		margin: 0;
		padding: 0;
		list-style: none;
		grid-template-columns: 1fr 1fr 1fr 1fr;

		@media (max-width: 767px) {
			grid-template-columns: 1fr 1fr;
		}

		@media (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}

	&__item {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-template-areas: 'a' 'b';
		box-shadow: 0px 4px 21px rgba(157, 157, 157, 0.29);

		@media (max-width: 575px) {
			display: flex;
			flex-direction: column;
			max-width: 424px;
			width: 100%;
			margin: 0 auto;
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		p {
			margin-top: 0;
			line-height: 18px;
			text-align: center;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__image {
		position: relative;
		grid-area: a;

		@media (max-width: 767px) {
			padding-bottom: 65%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__item:nth-child(even) &__image {
		grid-area: b;
	}

	&__inner {
		grid-area: b;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 25px 10px;
		background: $block-bg-main;
	}

	&__item:nth-child(even) &__inner {
		grid-area: a;
	}

	&__title {
		margin: 0 0 16px;
		text-align: center;
	}

	&__text {
		margin: 0;
		line-height: 18px;
		text-align: center;
	}
}