.facts-table {
	table {
		table-layout: fixed;

		@media (max-width: 991px) {
			table-layout: auto;
		}
		th,
		td {
			border-right: 7.5px solid $bg-main-color;
			border-left: 7.5px solid $bg-main-color;
			min-width: 180px;
			max-width: 194px;
			min-height: 66px;

			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none
			}
		}

		th {
			padding: 16px;
			font-size: 32px;
			line-height: 36px;
			font-weight: 400;
			color: $text-main-color;
			background: $bg-second-color;
		}

		td {
			background: $additional-color;
			font-size: 22px;
			line-height: 25px;
			padding: 8px 16px;
		}

		tr:nth-child(even) td {
			background: $additional-color;
		}

		thead {
			position: relative;
			padding-bottom: 3px;
			&::after {
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				height: 3px;
				background: $accent-color;
				content: "";
			}
		}

		tbody tr {
			position: relative;
			padding-bottom: 3px;

			&::after {
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				height: 3px;
				background: $accent-color;
				content: "";
			}

			&:last-child {
				position: static;
				padding-bottom: 0;

				&::after {
					display: none;
				}
			}
		}
	}
}