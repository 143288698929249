.advantages {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -20px -60px;
		padding: 0;

		@media (max-width: 767px) {
			margin: 0 -10px -40px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: calc(100%/3);
		padding: 0 20px;
		margin-bottom: 60px;

		@media (max-width: 767px) {
			width: 50%;
			padding: 0 10px;
			margin-bottom: 40px;
		}

		@media (max-width: 575px) {
			width: 100%;
		}

		p {
			width: 100%;
			max-width: 300px;
			margin-top: 0;
			font-size: 16px;
			text-align: center;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		margin: 0 0 20px;

		img {
			width: 100%;
			height: auto;
			max-height: 60px;
			object-fit: contain;
		}
	}

	&__title {
		width: 100%;
		max-width: 300px;
		margin: 0 0 25px;
		font-size: 20px;
		line-height: 22.5px;
		color: $text-main-color;
		text-align: center;
	}
}