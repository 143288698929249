.advertising {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 73px 67px;
    border-radius: 15px;
    overflow: hidden;

    @media (max-width: 767px) {
        justify-content: center;
        padding: 50px;
    }

    @media (max-width: 575px) {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    >picture img,
    >img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 410px;
        max-width: 100%;

        p {
            width: 100%;
            margin: 0 0 52px;
            font-size: 20px;
            line-height: 112.5%;
            font-weight: 600;
            color: $additional-color-2;
            text-align: center;

            @media (max-width: 575px) {
                max-width: 210px;
                margin-bottom: 12px;
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}