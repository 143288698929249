.how-buy-block {

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -25px -40px;
		padding: 0;
		list-style: none;

		@media (max-width: 1199px) {
			margin-left: -20px;
			margin-right: -20px;
			margin-bottom: 0;
		}

		@media (max-width: 991px) {
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: -20px;
		}

		@media (max-width: 767px) {
			flex-direction: column;
			justify-content: flex-start;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: -40px;
		}
	}

	&__item {
		position: relative;
		width: 25%;
		padding: 0 25px 50px;

		@media (max-width: 1199px) {
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 40px;
		}

		@media (max-width: 991px) {
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 20px;
		}

		@media (max-width: 767px) {
			position: relative;
			width: 100%;
			max-width: 335px;
			padding-left: 82px;
			padding-right: 0;
			padding-bottom: 0;
			margin: 0 auto 40px;
		}

		&::after {
			position: absolute;
			top: 31px;
			left: 25px;
			width: calc(100% + 50px);
			height: 2px;
			background: $additional-color;
			content: "";

			@media (max-width: 1199px) {
				width: calc(100% + 40px);
			}

			@media (max-width: 991px) {
				width: calc(100% + 20px);
			}

			@media (max-width: 767px) {
				left: 31px;
				top: 0;
				width: 2px;
				height: calc(100% + 40px);
			}
		}

		&:nth-child(4n)::after {
			display: none;

			@media (max-width: 767px) {
				display: block;
			}
		}

		&:last-child::after {
			display: none;
		}

		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__icon {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 62px;
		height: 62px;
		margin-bottom: 24px;
		border-radius: 50%;
		border: 2px solid $additional-color;
		background: $bg-second-color;

		@media (max-width: 767px) {
			position: absolute;
			top: 0;
			left: 0;
		}

		img {
			width: auto;
			height: auto;
			max-width: calc(100% - 20px);
			max-height: calc(100% - 20px);
			object-fit: contain;
		}
	}

	&__title {
		margin: 0 0 16px;

		@media (max-width: 991px) {
			font-size: 18px;
			line-height: 112.5%;
			margin-bottom: 12px;
		}
	}

	&__btn {
		margin-top: 40px;

		@media (max-width: 767px) {
			display: block;
			width: 100%;
			max-width: 335px;
			margin: 40px auto 0;
		}
	}
}