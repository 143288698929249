.stages-section {

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px -30px;
		padding: 0;
		list-style-type: none; 
		counter-reset: num;

		@media (max-width: 991px) {
			flex-direction: column;
			justify-content: flex-start;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__item {
		position: relative;
		display: flex;
		width: calc(100%/3);
		padding: 0 15px;
		margin-bottom: 30px;
		overflow: hidden;

		@media (max-width: 991px) {
			width: 100%;
			max-width: 335px;
			min-height: 190px;
			padding-left: 0;
			padding-right: 0;
			margin-left: auto;
			margin-right: auto;
		}

		&::after {
			position: absolute;
			display: block;
			content: counter(num);
			counter-increment: num;
			bottom: -70px;
			right: 5px;
			font-size: 220px;
			line-height: 1;
			color: $bg-main-color;
			font-weight: 600;
			text-align: center;
		}
	}

	&__inner {
		padding: 28px 90px 28px 24px;
		background: $bg-second-color;
		border-radius: 5px;

		p {
			position: relative;
			z-index: 2;
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		position: relative;
		z-index: 2;
		margin: 0 0 20px;
		font-size: 20px;
		line-height: 112.5%;
		font-weight: 600;
		color: $accent-color;
	}

	&__btn {
		display: block;
		margin-top: 22px;
		margin-left: auto;
		width: calc(100%/3 - 20px);

		@media (max-width: 991px) {
			width: 100%;
			max-width: 335px;
			margin-right: auto;
		}
	}
}