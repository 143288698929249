.gallery {
	&__list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		width: calc(100%/5);

		@media (max-width: 991px) {
			width: 163px;
		}

		@media (max-width: 767px) {
			width: 144px;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__img {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 146%;
		margin-bottom: 8px;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__caption {
		width: 100%;
		margin: 0;
		font-size: 16px;
		line-height: 18px;
		font-weight: 300;
		color: $additional-color-2;
	}
}