.contacts {
	&__wrapper {
		position: relative;
		overflow: hidden;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -2px -20px 0;
		padding: 0;
		list-style: none;

		@media (max-width: 575px) {
			flex-direction: column;
			justify-content: flex-start;
			margin-right: 0;
			margin-top: -40px;
			margin-bottom: 0;
		}
	}

	&__item {
		width: 25%;
		padding: 19px 11px 19px;
		border-right: 2px solid $text-third-color;
		margin-bottom: 20px;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 575px) {
			width: 100%;
			max-width: 245px;
			margin: 0 auto;
			padding: 40px 0;
			border-right: 0;
			border-bottom: 2px solid $text-third-color;
		}

		&:last-child {
			border-right: none;

			@media (max-width: 575px) {
				border-bottom: none;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	&__icon {
		width: 40px;
		height: 40px;
		margin-bottom: 32px;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			fill: $accent-color;
		}
	}

	&__title {
		margin: 0 0 12px;
		font-size: 16px;
		line-height: 18.5px;
		font-weight: 500;
	}

	&__text {
		display: inline-block;
		vertical-align: baseline;
		margin: 0;
		font-size: 22px;
		line-height: 112.5%;
		text-decoration: none;

		@media (max-width: 991px) {
			font-size: 20px;
		}
	}

	a {
		&:hover,
		&:focus {
			color: $accent-color;
			text-decoration: none;
		}
	}
}