input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: $text-third-color;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: $text-third-color;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: $text-third-color;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: $text-third-color;
}

input:hover::-webkit-input-placeholder,
textarea:hover::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:hover::-moz-placeholder,
textarea:hover::-moz-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:hover:-moz-placeholder,
textarea:hover:-moz-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:hover:-ms-input-placeholder,
textarea:hover:-ms-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.form-control {
	position: relative;
	margin-bottom: 16px;

	label {
		display: flex;
		flex-direction: column;
		font-size: 16px;
		line-height: 18px;
		text-align: left;

		span {
			margin-bottom: 2.5px;
		}
	}

	input,
	textarea {
		width: 100%;
		height: 40px;
		border: 1px solid $text-third-color;
		border-radius: 3px;
		font-size: 16px;
		line-height: 18px;
		font-weight: 300;
		color: $text-third-color;
		padding: 11px 10px;
		transition: all 0.3s ease-in-out;

		&:hover,
		&:focus {
			color: $text-main-color;
		}
	}

	textarea {
		height: 144px;
	}

	&.active input,
	&.active textarea {
		color: $text-main-color;
	}

	&.error input,
	&.error textarea {
		border-color: $bg-red;
	}

	&--consent {
		position: relative;
		input {
			position: absolute;
			left: 0;
			top: 0;
			width: 24px;
			height: 24px;
			opacity: 0;
			pointer-events: none;
		}

		label {
			display: inline-block;
			vertical-align: baseline;
			padding-left: 36px;
			font-size: 12px;
			line-height: 14px;
			text-align: left;
			cursor: pointer;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 24px;
				height: 24px;
				border: 2px solid $text-main-color;
				transition: all 0.3s ease-in-out;
				content: '';
			}

			&::after {
				position: absolute;
				top: 6px;
				left: 5px;
				width: 14px;
				height: 8px;
				border-bottom: 2px solid $text-main-color;
				border-left: 2px solid $text-main-color;
				transform: rotate(-45deg) scale(0);
				opacity: 0;
				transition: all 0.3s ease-in-out;
				content: '';
			}

			&:hover {
				&::before,
				&::after {
					border-color: $accent-color;
				}
			}
		}

		input:checked + label::after {
			transform: rotate(-45deg) scale(1);
			opacity: 1;
		}
	}
}

.error-message {
	bottom: 0;
	width: 100%;
	padding: 0 10px;
	color: $bg-red;
	font-size: 12px;
	line-height: 14px;
	// text-transform: lowercase;
	font-weight: 400;
	letter-spacing: 0.02em;
	position: absolute;
	opacity: 0;
	transform: scale(0);
	pointer-events: none;
	transition: all 0.3s ease-in-out;
}

.error .error-message {
	opacity: 1;
	transform: scale(1);
}

.color-inversion .form-control--consent label,
.color-inversion .form-control--consent label, {
	&::before {
		border-color: $text-color-inversion;
	}

	&::after {
		border-color: $text-color-inversion;
	}

	&:hover {
		&::before,
		&::after {
			border-color: $accent-color;
		}
	}
}
