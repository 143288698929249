.reviews-block {

	&__slider {
		width: 780px;
		max-width: calc(100% - 60px);
		margin: 0 auto;

		@media (max-width: 767px) {
			max-width: calc(100% - 80px);
		}
	}

	&__list {
		display: flex;
		align-items: flex-start;
		margin: 0;
		padding: 0;
	}

	&__item {
		width: 100%;
		flex-shrink: 0;
		text-align: center;
	}

	&__name {
		width: 100%;
		margin: 0 0 12px;
		font-size: 20px;
		line-height: 22.5px;
		font-weight: 600;
	}

	&__date {
		width: 100%;
		font-size: 12px;
		line-height: 14px;
	}

	&__header {
		max-width: 510px;
		margin: 0 auto 16px;
	}

	&__content {
		max-width: 510px;
		margin: 0 auto;
		p {
			width: 100%;
			margin: 0;
		}
	}

	&__nav {
		@media (max-width: 991px) {
			display: block;
		}

		&--prev {
			@media (max-width: 767px) {
				left: -40px;
			}	
		}

		&--next {
			@media (max-width: 767px) {
				right: -40px;
			}
		}	
	}
}